import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useCreateLeadServicesMutation } from "../../services";
import Logo from "../../assets/Logo/logo.png";

export const Services = () => {
  const [createLeadServices, { isLoading, isSuccess, error }] =
    useCreateLeadServicesMutation();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const onSubmit = (data) => createLeadServices(data);

  const [showAltAddress, setShowAltAddress] = useState(false);

  const toggleAltAddress = () => {
    setShowAltAddress(!showAltAddress);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-svijetloPlava1 to-blue-500 ">
      <div className="w-full max-w-md p-6 md:p-8 space-y-3 md:space-y-4 bg-white bg-opacity-50 shadow-lg rounded-lg mt-[110px] mb-[15px]">
        <img
          src={Logo}
          alt="Logo Familia"
          className="w-[250px] md:w-[250px] lg:w-[250px] mx-auto"
        />
        <h2 className="text-2xl font-poppins md:text-3xl font-normal text-center text-gray-700">
          Forma
        </h2>
        <h2 className="text-xl font-poppins md:text-xl font-normal text-center text-gray-700">
          Podatci o pacijentu
        </h2>
        <p className="text-center font-light text-gray-700  ">
          Molimo vas da popunite ovu formu kako bismo dobili više informacija.
          Naš prodajni tim će vas uskoro kontaktirati.
        </p>
        <hr className="my-4 border-t-4 border-TamnoPlava rounded-full w-[100%] lg:w-[90%] mx-auto" />
        <form
          className="mt-6 md:mt-8 space-y-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="text-red-600">{error?.data.message}</div>
          <p className="text-center md:text-left">Pacijent</p>
          <Controller
            name="pacijent_c"
            control={control}
            defaultValue=""
            rules={{
              required: "Ime i prezime je obavezno.",
              pattern: { value: /^[^\s].*/, message: "Unesite ispravno ime." },
            }}
            render={({ field: { ref, value, onChange } }) => (
              <>
                <input
                  ref={ref}
                  required
                  id="pacijent-c"
                  placeholder="Ime i Prezime"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  value={value}
                  onChange={onChange}
                />
                {errors.pacijent_c && (
                  <p className="text-red-500 text-sm">
                    {errors.pacijent_c.message}
                  </p>
                )}
              </>
            )}
          />

          <Controller
            name="dijagnoza_c"
            control={control}
            defaultValue=""
            rules={{
              required: "Dijagnoza je obavezna.",
              pattern: {
                value: /^[^\s].*/,
                message: "Unesite ispravnu dijagnozu.",
              },
            }}
            render={({ field: { ref, value, onChange } }) => (
              <>
                <input
                  ref={ref}
                  required
                  id="dijagnoza-c"
                  placeholder="Dijagnoza"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  value={value}
                  onChange={onChange}
                />
                {errors.dijagnoza_c && (
                  <p className="text-red-500 text-sm">
                    {errors.dijagnoza_c.message}
                  </p>
                )}
              </>
            )}
          />

          <p className="text-center md:text-left">Odaberi uslugu</p>
          <Controller
            name="servisi_c"
            control={control}
            defaultValue="1"
            rules={{ required: true }}
            render={({ field: { ref, value, onChange } }) => (
              <select
                ref={ref}
                required
                id="servisi_c"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={value}
                onChange={onChange}
              >
                <option value="dom">Smještaj i njega paijenta u Dom</option>
                <option value="dom1">
                  Smještaj i njega pacijenata sa demencijom
                </option>
                <option value="dom2">
                  Smještaj i njega pacijnata sa popnašajnim i psihotičnim
                  manifestacijama bolesti
                </option>
                <option value="dom3">
                  Smještaj njega i rehabilitacija nakon CVI (cerebrovaskularni
                  incident)
                </option>
                <option value="dom4">
                  Smještaj i rehabilitacija nakon loma kuka
                </option>
                <option value="dom5">
                  Smještaj njega i kardiopulmonalna rehabilitacija
                </option>
                <option value="dom6">
                  Opšta rehabilitacija nakon operativnih zahvata i bolničkih
                  tretmana
                </option>
                <option value="dom7">
                  Smještaj i njega pacijenata sa terminalno uznapredovalom
                  bolesti
                </option>
                <option value="dom8">
                  Rehabilitacija bez smještaja po tipu dnevnog boravka
                </option>
                <option value="dom9">
                  Robotska rehabilitacija neuroloških bolesnika
                </option>
                <option value="dom10">Ostalo </option>
              </select>
            )}
          />

          <p className="text-center md:text-left">Podatci o stanovanju</p>
          <Controller
            name="primary_address_city"
            control={control}
            defaultValue=""
            rules={{
              required: "Grad je obavezan.",
              pattern: { value: /^[^\s].*/, message: "Unesite ispravan grad." },
            }}
            render={({ field: { ref, value, onChange } }) => (
              <>
                <input
                  ref={ref}
                  required
                  id="city"
                  placeholder="Grad"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  value={value}
                  onChange={onChange}
                />
                {errors.primary_address_city && (
                  <p className="text-red-500 text-sm">
                    {errors.primary_address_city.message}
                  </p>
                )}
              </>
            )}
          />

          <Controller
            name="primary_address_street"
            control={control}
            defaultValue=""
            rules={{ required: true, pattern: /^[^\s].*/ }}
            render={({ field: { ref, value, onChange } }) => (
              <input
                ref={ref}
                required
                id="street"
                placeholder="Adresa"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name="primary_address_country"
            control={control}
            defaultValue=""
            rules={{ required: true, pattern: /^[^\s].*/ }}
            render={({ field: { ref, value, onChange } }) => (
              <input
                ref={ref}
                required
                id="country"
                placeholder="BIH/SRB/HR"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="primary_address_state"
            control={control}
            defaultValue=""
            rules={{ required: true, pattern: /^[^\s].*/ }}
            render={({ field: { ref, value, onChange } }) => (
              <input
                ref={ref}
                id="primary-address-state"
                required
                placeholder="Federacija/RS"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name="primary_address_postalcode"
            control={control}
            defaultValue=""
            rules={{ required: true, pattern: /^[^\s].*/ }}
            render={({ field: { ref, value, onChange } }) => (
              <input
                ref={ref}
                required
                id="primary-address-postalcode"
                placeholder="Poštanski broj"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={value}
                onChange={onChange}
              />
            )}
          />

          <div>
            <button
              onClick={toggleAltAddress}
              className="mb-4 px-4 py-2 text-dark underline "
            >
              {showAltAddress
                ? "Sakrij alternativnu adresu"
                : "Dodaj alternativnu adresu"}
            </button>

            {showAltAddress && (
              <div>
                <p className="">Podatci o alternativnom stanovanju (opciono)</p>

                <Controller
                  name="alt_address_city"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, pattern: /^[^\s].*/ }}
                  render={({ field: { ref, value, onChange } }) => (
                    <input
                      ref={ref}
                      id="alt-address-city"
                      placeholder="Grad"
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />

                <Controller
                  name="alt_address_street"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, pattern: /^[^\s].*/ }}
                  render={({ field: { ref, value, onChange } }) => (
                    <input
                      ref={ref}
                      id="alt-address-street"
                      placeholder="Adresa"
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />

                <Controller
                  name="alt_address_country"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, pattern: /^[^\s].*/ }}
                  render={({ field: { ref, value, onChange } }) => (
                    <input
                      ref={ref}
                      id="alt-address-country"
                      placeholder="BIH/SRB/HR"
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />

                <Controller
                  name="alt_address_state"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, pattern: /^[^\s].*/ }}
                  render={({ field: { ref, value, onChange } }) => (
                    <input
                      ref={ref}
                      id="alt-address-state"
                      placeholder="Federacija/RS"
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />

                <Controller
                  name="alt_address_postalcode"
                  control={control}
                  defaultValue=""
                  rules={{ required: true, pattern: /^[^\s].*/ }}
                  render={({ field: { ref, value, onChange } }) => (
                    <input
                      ref={ref}
                      id="alt-address-postalcode"
                      placeholder="Poštanski broj"
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            )}
          </div>

          <Controller
            name="description"
            control={control}
            defaultValue=""
            rules={{
              required: "Opis je obavezan.",
              pattern: { value: /^[^\s].*/, message: "Unesite ispravan opis." },
            }}
            render={({ field: { ref, value, onChange } }) => (
              <>
                <textarea
                  ref={ref}
                  required
                  id="description"
                  placeholder="Detaljan opis"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  value={value}
                  onChange={onChange}
                />
                {errors.description && (
                  <p className="text-red-500 text-sm">
                    {errors.description.message}
                  </p>
                )}
              </>
            )}
          />
          <p
            className={`text-center font-semibold ${
              isSuccess ? "text-green-500" : "text-red-500"
            }`}
          >
            {isSuccess ? "Hvala na popunjenoj formi!" : error?.data.message}
          </p>
          <button
            id="submit-button"
            disabled={!isValid || isLoading || isSuccess}
            className="w-full h-[50px] border-[3px] border-Narandzasta rounded-2xl transition-all duration-300 ease-in-out cursor-pointer bg-white text-TamnoPlava font-semibold text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
            type="submit"
          >
            {isLoading ? "Šaljem podatke..." : "Pošalji"}
          </button>
        </form>
      </div>
    </div>
  );
};
