import queries from './queries';

const authService = queries.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (register) => {
        return {
          method: 'POST',
          url: '/auth/register',
          body: register
        };
      }
    }),
    login: builder.mutation({
      query: (login) => {
        return {
          method: 'POST',
          url: '/auth/login',
          body: login
        };
      }
    }),
    verify: builder.mutation({
      query: (verifyToken) => {
        return {
          method: 'POST',
          url: '/auth/verify',
          body: {token: verifyToken}
        };
      }
    }),
    forgotPassword: builder.mutation({
      query: (forgotPassword) => {
        return {
          method: 'POST',
          url: '/auth/forgot-password',
          body: forgotPassword
        };
      }
    }),
    resetPassword: builder.mutation({
      query: (resetPassword) => {
        return {
          method: 'POST',
          url: '/auth/reset-password',
          body: resetPassword
        };
      }
    })
  })
});

export default authService;
