import React from "react";
import Rehabilitacija1 from "../../assets/images/RehabilitacijaOsoba/rehabilitacija1.jpeg";
import { Link } from "react-router-dom";
import Rehabilitacija2 from "../../assets/images/RehabilitacijaOsoba/rehabilitacija2.jpg";
import Rehabilitacija3 from "../../assets/images/RehabilitacijaOsoba/rehabilitacija3.jpg";
import Rehabilitacija4 from "../../assets/images/RehabilitacijaOsoba/rehabilitacija 4.jpg";
import Rehabilitacija5 from "../../assets/images/RehabilitacijaOsoba/rehabilitacija5.jpg";
import Rehabilitacija6 from "../../assets/images/RehabilitacijaOsoba/rehabilitacija6.jpg";
import Rehabilitacija7 from "../../assets/images/RehabilitacijaOsoba/rehabilitacija7.jpg";
import Rehabilitacija8 from "../../assets/images/RehabilitacijaOsoba/rehabilitacija8.jpg";
import Rehabilitacija9 from "../../assets/images/RehabilitacijaOsoba/rehabilitacija9.jpg";
import Rehabilitacija10 from "../../assets/images/RehabilitacijaOsoba/rehabilitacija10.jpg";
import Rehabilitacija12 from "../../assets/images/RehabilitacijaOsoba/rehabilitacija12.jpg";
import Rehabilitacija13 from "../../assets/images/Fizikalna/biodex2.jpg";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

export const Rehabilitacija = () => {
  const cardData = [
    {
      image: Rehabilitacija2,
      title: "Kardijalna rehabilitacija",
      description:
        "Kardijalna rehabilitacija je poseban aspekt rehabilitacije koji se usredotočuje na oporavak i poboljšanje kardiovaskularnog zdravlja pacijenata nakon srčanih događaja, operacija ili bolesti.",
      buttonText: "Kontakt",
    },
    {
      image: Rehabilitacija3,
      title: "Opšta rehabilitacija",
      description:
        "Opšta rehabilitacija pruža sveobuhvatan oporavak i podršku pacijentima koji su doživjeli različite zdravstvene događaje, s ciljem poboljšanja funkcionalnih sposobnosti i kvalitete života.",
      buttonText: "Kontakt",
    },
    {
      image: Rehabilitacija4,
      title: "Neurodegenerativna rehabilitacija",
      description:
        "Neurodegenerativna rehabilitacija usmjerena je na pružanje podrške i terapije pacijentima s neurodegenerativnim poremećajima, poboljšavajući njihove kognitivne i motoričke funkcije te kvalitetu života.",
      buttonText: "Kontakt",
    },
    {
      image: Rehabilitacija5,
      title: "Postoperativna rehabilitacija",
      description:
        "Postoperativna rehabilitacija pruža strukturirani oporavak i terapiju pacijentima nakon kirurških zahvata, s ciljem ubrzanja zacjeljivanja, obnove funkcija i optimizacije kvalitete života.",
      buttonText: "Kontakt",
    },
    {
      image: Rehabilitacija6,
      title: "Rehabilitacija nakon moždanog udara",
      description:
        "Rehabilitacija nakon moždanog udara fokusira se na oporavak pacijenata kroz ciljane terapije, obnavljanje kognitivnih i motoričkih funkcija te poboljšanje kvalitete života.",
      buttonText: "Kontakt",
    },
    {
      image: Rehabilitacija7,
      title: "Rehabilitacija koljena",
      description:
        "Rehabilitacija koljena pruža specijaliziranu terapiju i podršku pacijentima s ozljedama ili operacijama koljena, s ciljem obnove pokretljivosti, snage i funkcije zgloba.",
      buttonText: "Kontakt",
    },
  ];

  return (
    <HelmetProvider>
      <div className="w-full bg-svijetloPlava1 mt-[96px]">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Rehabilitacija Familia</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <div className="w-full md:flex md:flex-row md:px-10  ">
          <div className="w-full  xl:w-[70%] 2xl:w-[50%] ">
            <img
              src={Rehabilitacija1}
              alt="rehabilitacija starijih osoba "
              className="w-full"
            />
          </div>
          <div className="w-[100%] p-5 md:w-[80%] left-28 bg-svijetloPlava1 text-TamnoPlava ">
            <h2 className="text-3xl break-words xl:text-5xl 2xl:text-5xl align-top text-TamnoPlava mb-10 mt-10  font-extrabold">
              Rehabilitacija za starije osobe
            </h2>
            <p className="font-extralight text-base text-TamnoPlava xl:text-base 2xl:text-xl w-full 2xl:w-[85%] xl:pl-5 2xl:pl-5">
              Rehabilitacija u ustanovi Familia pruža sveobuhvatnu i
              personaliziranu medicinsku njegu, prilagođenu specifičnim
              potrebama pacijenta sa ciljem obnove neovisnosti, mobilnosti i
              poboljšanja kvalitete života nakon bolesti, ozljeda ili operacija.
              <br /> Ustanova Familia nudi rehabilitaciju uz pomoć stručnog
              osoblja i multidisciplinarnog tima, pružajući starijim pacijentima
              sveobuhvatnu skrb za što samostalniji i bolji kvalitet života.
            </p>
            <div className="flex flex-col justify-center items-center text-center md:flex lg:flex-row lg:justify-start lg:gap-2">
              <Link
                onClick={() => {
                  document
                    .getElementById("cardData")
                    .scrollIntoView({ behavior: "smooth" });
                }}
                className="w-52 lg:w-36 mt-10 h-12 border-[3px] border-Narandzasta rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-krem text-TamnoPlava font-semibold text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
              >
                Vise
              </Link>
              <Link
                onClick={() => {
                  document
                    .getElementById("rehabilitacija")
                    .scrollIntoView({ behavior: "smooth" });
                }}
                className="w-52 lg:w-36 mt-10 h-12 border-[3px] border-Narandzasta rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-krem text-TamnoPlava font-semibold text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
              >
                Opsta rehab
              </Link>
              <Link
                onClick={() => {
                  document
                    .getElementById("mozdaniUdar")
                    .scrollIntoView({ behavior: "smooth" });
                }}
                className="w-52 lg:w-36 mt-10 h-12 border-[3px] border-Narandzasta rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-krem text-TamnoPlava font-semibold text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
              >
                Mozdani Udar
              </Link>
              <Link
                onClick={() => {
                  document
                    .getElementById("lomKuka")
                    .scrollIntoView({ behavior: "smooth" });
                }}
                className="w-60 lg:w-60 mt-10 h-12 border-[3px] border-Narandzasta rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-krem text-TamnoPlava font-semibold text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
              >
                Rehab nakon loma kuka
              </Link>
            </div>
          </div>
        </div>
        {/*Rehabilitacija za starije osobe u Ustanovi Familia*/}
        <div className="bg-gray-200">
          <div className="px-10 py-20 flex flex-col justify-center items-center">
            <h1 className="text-3xl  text-left justify-center flex flex-row text-Narandzasta md:text-3xl lg:text-4xl font-extrabold mb-5 sm:mb-8">
              Rehabilitacija za starije osobe u Ustanovi Familia
            </h1>
            <ul className="custom-bullets px-0 sm:px-32 md:px-40 lg:px-52  text-start mt-5 text-base md:text-lg  font-extralight">
              <li>
                Opća i medicinska njega u Ustanovi Familia s naglaskom na
                neovisnost, dostojanstvo i kvalitetu života
              </li>
              <li>
                Personalizirani pristup prilagođen specifičnim potrebama
                pacijenta
              </li>
              <li>Edukacija o prevenciji pada i sigurnosti</li>
              <li>Multidisciplinarni tim stručnjaka</li>
            </ul>
          </div>
        </div>

        {/*Opsta rehabilitacija*/}
        <div id="rehabilitacija" className="w-full">
          <div className="relative  lg:flex bg-TamnoPlava justify-center">
            <div className="lg:w-1/2 xl:w-[45%] 2xl:w-[28%]">
              <div className="relative">
                <img
                  loading="lazy"
                  className="w-full"
                  src={Rehabilitacija9}
                  alt="Hronicne rane previjanje"
                />
                <div className="absolute inset-0 bg-black bg-opacity-20"></div>
              </div>
            </div>
            <div className="p-5 pb-20 md:pb-10   lg:pl-8 text-krem w-[95%] md:w-[80%] font-extralight lg:w-[55%] xl:w-[55%] 2xl:w-[65%] pt-16 text-left md:text-left">
              <h1 className="text-4xl text-center lg:text-left sm:text-5xl text-krem font-bold mb-5 ">
                Opšta rehabilitacija
              </h1>
              <p className="text-base xl:text-lg 2xl:text-2xl text-center lg:text-left text-Narandzasta font-semibold p-0 md:px-10 ">
                Sveobuhvatna rehabilitacija osoba: fizikalna terapija,
                psihološka podrška i socijalne aktivnosti
              </p>
              <div className="w-full md:w-[100%] p-2 sm:p-4">
                <ul className="custom-bullets pt-5 text-lg xl:text-lg 2xl:text-xl mt-2 px-2  font-extralight">
                  <h2 className="text-lg font-poppins mb-3">
                    <b>Redovno vježbanje </b> ima brojne fizičke, mentalne i
                    emocionalne koristi za starije osobe te je ključno za
                    očuvanje njihovog zdravlja i kvalitete života u kasnijim
                    godinama. Navedimo samo neke:
                  </h2>
                  <li>
                    <b>Održavanje funkcionalnosti tijela: </b> SRedovnim
                    vježbanjem starije osobe održavaju i poboljšavaju
                    funkcionalnost mišića, zglobova i kostiju. To može značajno
                    smanjiti rizik od pada i ozljeda, te održati neovisnost u
                    svakodnevnim aktivnostima poput hodanja, penjanja
                    stepenicama ili obavljanja kućnih poslova.
                  </li>
                  <li>
                    <b>Jačanje srca i krvožilnog sisitema: </b> Vježbanjem se
                    poboljšava cirkulacija krvi, jača srce i smanjuje rizik od
                    kardiovaskularnih bolesti poput srčanog udara ili moždanog
                    udara.
                  </li>
                  <li>
                    <b>Očuvanje mentalnog zdravlja: </b> Redovna fizička
                    aktivnost pomaže u održavanju mentalnog zdravlja starijih
                    osoba. Smanjuje rizik od depresije, anksioznosti i demencije
                    te poboljšava opšte raspoloženje i kvalitet života.
                  </li>
                  <li>
                    <b>Poboljšanje kvalitete sna: </b> Aktivni stil života često
                    dovodi do boljeg sna, što je važno za oporavak i cjelokupno
                    zdravlje.
                  </li>
                  <li>
                    <b>Socijalni aspekti:</b> Učestvovanje u grupnim ili
                    individualnim vježbama može starijim osobama pružiti priliku
                    za druženje i socijalnu interakciju, što također doprinosi
                    opštem osjećaju zadovoljstva.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*Rehab nakon mozdanog udara */}
          <div
            id="mozdaniUdar"
            className="relative lg:flex lg:flex-row-reverse bg-TamnoPlava justify-center"
          >
            <div className="lg:w-1/2 xl:w-[45%] 2xl:w-[28%]">
              <div className="relative">
                <img
                  loading="lazy"
                  className="w-full"
                  src={Rehabilitacija10}
                  alt="Hronicne rane previjanje"
                />
                <div className="absolute inset-0 bg-black bg-opacity-20"></div>
              </div>
            </div>
            <div className="p-5 pb-20 md:pb-10   lg:pl-8 text-krem w-[95%] md:w-[80%] font-extralight lg:w-[55%] xl:w[55%] 2xl:w-[65%] pt-20 text-left md:text-left">
              <h1 className="text-4xl text-center lg:text-left sm:text-5xl text-krem font-bold mb-7 ">
                Rehabilitacija nakon moždanog udara
              </h1>
              <p className="text-base xl:text-lg 2xl:text-2xl text-center lg:text-left text-Narandzasta font-semibold p-0 md:px-10 ">
                Ciljevi rehabilitacije kod pacijenta nakon prebolovanog moždanog
                udara su:
              </p>
              <div className="w-full md:w-[100%] p-2 sm:p-4">
                <ul className="custom-bullets pt-5 text-lg xl:text-lg 2xl:text-xl mt-2 px-2  font-extralight">
                  <li>
                    <b>Obnova motoričkih funkcija:</b> Poboljšanje
                    pokretljivosti, snage mišića i koordinacije.
                  </li>
                  <li>
                    <b>Povratak samostalnosti:</b> Omogućavanje pacijentima da
                    obavljaju svakodnevne aktivnosti bez pomoći.
                  </li>
                  <li>
                    <b>Prevencija komplikacija:</b> Sprječavanje sekundarnih
                    komplikacija poput dekubitusa, krvnih ugrušaka i upale
                    pluća.
                  </li>
                  <li>
                    <b>Poboljšanje kvalitete života:</b>Podrška pacijentima u
                    postizanju optimalne fizičke i kognitivne funkcije.
                  </li>
                  <li>
                    <b>Socijalna i emocionalna podrška:</b> Pružanje psihološke
                    podrške i reintegracija u društvene aktivnosti.
                  </li>
                </ul>
                <p className="text-lg lg:text-xl font-medium text-krem mt-10">
                  Rehabilitacija nakon moždanog udara temelji se na
                  neuroplastičnosti, omogućavajući preostalim neuronima da
                  preuzmu funkcije izgubljene oštećenjem moždanog tkiva. Ključni
                  principi uključuju:
                </p>
                <ul className="custom-bullets pt-5 text-lg xl:text-lg 2xl:text-xl mt-2 px-3  font-extralight">
                  <li>
                    <b>Intenzivno vježbanje pokreta </b> kako bi se stimulirali
                    preostali neuroni.
                  </li>
                  <li>
                    <b>Početak rehabilitacije </b> odmah nakon moždanog udara,
                    sa preporučenom terapijom od najmanje 3 sata dnevno, 5-6
                    dana sedmično.
                  </li>
                  <li>
                    <b>Dugoročno provođenje </b> rehabilitacije tokom 12-15
                    sedmica za optimalne rezultate u preživljavanju i kvaliteti
                    života.
                  </li>
                </ul>
                <div className="mt-14">
                  <Link
                    to="/mozdaniUdar"
                    className=" text-[20px] md:text-[24px] underline text-Narandzasta font-normal hover:text-white  "
                  >
                    Pročitaj više o mozdanom udaru
                    <FontAwesomeIcon className="ml-3" icon={faArrowRightLong} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/*Robotska rehabilitacijaa*/}
          <div
            id="mozdaniUdar"
            className="relative lg:flex lg:flex-row bg-TamnoPlava justify-center"
          >
            <div className="lg:w-1/2 xl:w-[45%] 2xl:w-[28%]">
              <div className="relative">
                <img
                  loading="lazy"
                  className="w-full h-[900px] object-cover"
                  src={Rehabilitacija13}
                  alt="Robotska rehabilitacija"
                />
                <div className="absolute inset-0 bg-black bg-opacity-20"></div>
              </div>
            </div>
            <div className="p-5 pb-20 md:pb-10   lg:pl-8 text-krem w-[95%] md:w-[80%] font-extralight lg:w-[55%] xl:w[55%] 2xl:w-[65%] pt-20 text-left md:text-left">
              <h1 className="text-4xl text-center lg:text-left sm:text-5xl text-krem font-bold mb-7 ">
                Robotska rehabilitacija
              </h1>
              <p className="text-base xl:text-lg 2xl:text-2xl text-center lg:text-left text-Narandzasta font-semibold p-0 md:px-10 ">
                Napredne tehnologije u robotskoj rehabilitaciji za oporavak
                pacijenata nakon moždanog udara
              </p>
              <div className="w-full md:w-[100%] p-2 sm:p-4">
                <p className="text-lg font-normal">
                  Robotska rehabilitacija koristi napredne tehnologije za pomoć
                  pacijentima u obnavljanju funkcionalnih sposobnosti. U centru
                  za rehabilitaciju nakon mozdanog udara aktivno koristimo
                  napredne tehnologije da bi smo sto efikasnije unaprijedili
                  oporavak pacijenata nakon CVI. Aktivno svakodnevno koristimo:
                </p>
                <ul className="custom-bullets pt-5 text-lg xl:text-lg 2xl:text-xl mt-2 px-2  font-extralight">
                  <li>Robotski izokinetički dinamometar Biodex sistem 4</li>
                  <li>Robotizirani aparati za vježbanje ruku</li>
                  <li>Robot Ratlus</li>
                  <li>Biodex Sistem za rasterećeni hod</li>
                  <li>Biodex balans ploča</li>
                </ul>
                <h2 className="text-lg font-poppins lg:text-xl font-normal text-krem mt-10">
                  Intenzivna rehabilitacija neposredno nakon moždanog udara može
                  značajno poboljšati rezultate oporavka. Ključni faktori
                  uključuju:
                </h2>
                <ul className="custom-bullets pt-5 text-lg xl:text-lg 2xl:text-xl mt-2 px-3  font-extralight">
                  <li>
                    <b>Brži oporavak funkcija:</b> Veća je vjerovatnost da će
                    pacijenti povratiti izgubljene funkcije ako se
                    rehabilitacija započne rano.
                  </li>
                  <li>
                    <b>Poboljšana kvaliteta života:</b> Intenzivna
                    rehabilitacija može pomoći pacijentima da se vrate svojim
                    svakodnevnim aktivnostima i poboljšaju kvalitetu života.
                  </li>
                </ul>
                <div className="mt-14">
                  <Link
                    to="/robotskaRehab"
                    className=" text-[20px] md:text-[24px] underline text-Narandzasta font-normal hover:text-white  "
                  >
                    Pročitaj više o robotskoj rehab
                    <FontAwesomeIcon className="ml-3" icon={faArrowRightLong} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/*Lommmmm kukaaaa*/}
          <div
            id="lomKuka"
            className="relative  lg:flex lg:flex-row-reverse bg-TamnoPlava justify-center"
          >
            <div className="lg:w-1/2 xl:w-[45%] 2xl:w-[28%]">
              <div className="relative">
                <img
                  loading="lazy"
                  className="w-full"
                  src={Rehabilitacija12}
                  alt="Rehabilitacija loma kuka"
                />
                <div className="absolute inset-0 bg-black bg-opacity-20"></div>
              </div>
            </div>
            <div className="p-5 pb-20 md:pb-10   lg:pl-8 text-krem w-[95%] md:w-[80%] font-extralight lg:w-[55%] xl:w[55%] 2xl:w-[65%] pt-20 text-left md:text-left ">
              <h1 className="text-4xl text-center lg:text-left sm:text-5xl text-krem font-bold mb-7 ">
                Rehabilitacija nakon loma kuka
              </h1>
              <p className="text-base xl:text-lg 2xl:text-xl text-center lg:text-left text-krem font-semibold p-0 md:px-10 ">
                Lom kuka, najčešće u gornjem dijelu butne kosti (femur), ima
                ozbiljne posljedice, uključujući visoku stopu smrtnosti,
                naročito kod starijih osoba. Brza i odgovarajuća medicinska
                njega i rehabilitacija su ključne za siguran i efikasan
                oporavak. U Ustanovi Familia, naš tim fizikalnih terapeuta
                svakodnevno radi sa pacijentima kroz personalizirani pristup,
                uključujući:
              </p>
              <div className="w-full md:w-[100%] p-2 sm:p-4">
                <ul className="custom-bullets pt-5 text-lg xl:text-lg 2xl:text-xl mt-2 px-2  font-extralight">
                  <li>
                    <b>Poboljšanje pokretljivosti i funkcije:</b> Fizikalna
                    terapija i vježbe za vraćanje raspona pokreta, snage mišića
                    i ravnoteže.
                  </li>
                  <li>
                    <b>Smanjenje rizika od komplikacija:</b> Prevencija krvnih
                    ugrušaka, upale pluća i dekubitusa.
                  </li>
                  <li>
                    <b>Ubrzanje oporavka:</b> Brži povratak normalnim
                    aktivnostima.
                  </li>
                  <li>
                    <b>Smanjenje rizika od budućih prijeloma:</b> Jačanje mišića
                    i poboljšanje ravnoteže za prevenciju padova i prijeloma.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="cardData" className="bg-TamnoPlava">
          <div className="grid p-4 md:p-10 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
            {cardData.map((card, index) => (
              <div
                key={index}
                className="border bg-krem border-gray-300 p-4 flex flex-col md:flex md:flex-row"
              >
                <img loading="lazy" src={card.image} alt="" className="mr-4" />
                <div>
                  <h2 className="text-TamnoPlava text-2xl p-2 font-extrabold">
                    {card.title}
                  </h2>
                  <p className="p-2 font-extralight text-TamnoPlava">
                    {card.description}
                  </p>
                  <Link
                    to="/kontakt"
                    className="w-36 mt-10 h-12 border-[3px] border-Narandzasta rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-krem text-TamnoPlava font-semibold text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
                  >
                    {card.buttonText}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full bg-krem">
          <div className="p-3 md:p-12 flex flex-col md:flex md:flex-col lg:flex lg:flex-row">
            <div className="">
              <img
                className="w-full"
                src={Rehabilitacija8}
                alt="rehabilitacija odmor"
              />
            </div>
            <div className="w-[100%] flex flex-col items-center lg:items-start bg-TamnoPlava p-2 md:p-10">
              <h1 className="text-4xl leading-relaxed text-center md:text-left break-words w-full  lg:w-[60%] md:text-5xl align-top text-krem mb-5  font-extrabold">
                Optimalan oporavak uz individualni pristup
              </h1>
              <h2 className="break-words w-[80%] lg:w-[50%] text-center md:text-left text-lg md:text-2xl font-extrabold text-Narandzasta">
                Besplatne konsultacije za vaš personalizirani plan
                rehabilitacije
              </h2>
              <p className="text-krem text-xl xl:text-xl 2xl:text-2xl   leading-normal mt-10 font-extralight w-[95%] lg:w-[80%]">
                PPozivamo Vas da zakažete besplatne konsultacije s našim
                ljekarom specijalistom i uradite funkcionalna mjerenja, kako
                bismo kreirali individualni plan rehabilitacije koji odgovara
                Vašim potrebama. Kontaktirajte nas za dogovor termina i
                zajednički ćemo raditi na unaprjeđenju Vašeg zdravlja i
                kvalitete života.
              </p>
              <div className="p-10 lg:p-0">
                <Link
                  to="/konsultacije"
                  className="w-36 mt-14 h-12 border-[3px] border-Narandzasta rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-krem text-TamnoPlava font-semibold text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
                >
                  Konsultacije
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};
