import { isRejectedWithValue } from '@reduxjs/toolkit';
import {envs} from '../config';

const queryErrorLogger = () =>
  (next) =>
    (action) => {
      if (isRejectedWithValue(action)) {
        if (!envs.isProduction) {
          console.log({action});
          console.warn('We got a rejected action!');
        }

        const {payload} = action;

        if (payload.data?.message) {
          // todo: implement notification service
        }
      }

      return next(action);
    };

export default queryErrorLogger;
