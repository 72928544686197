import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { v4 as uuidv4 } from 'uuid';
import { envs } from '../config';
import {
  getAuthToken,
  setAuthTokens,
  destroyAuthTokens,
  TokenType
} from '../utils';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: envs.app.apiUrl,
  prepareHeaders: (headers) => {
    const token = getAuthToken(TokenType.token);

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    headers.set('X-Familia-Correlation-Id', `familia-portal-${uuidv4()}`);

    return headers;
  }
});

const baseQueryWithReAuth = async (
  args,
  api,
  extraOptions
) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  if (result['error'] && (result['error'].status === 401 || result['error'].status === 403)) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshToken = getAuthToken(TokenType.refreshToken);

        destroyAuthTokens();

        const refreshResult = await baseQuery(
          {
            method: 'POST',
            url: '/user/refresh',
            headers: { Authorization: `Bearer ${refreshToken}` }
          },
          api,
          extraOptions
        );

        if (refreshResult['data']) {
          setAuthTokens(refreshResult['data']);

          result = await baseQuery(args, api, extraOptions);
        } else {
          destroyAuthTokens();
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export default baseQueryWithReAuth;
