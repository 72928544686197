import React from "react";
import Njega from "./Njega";


export const ONama = () => {
  return (
    <>
      <div className="">
        <Njega />
      </div>
      {/*<div className="">
        <Boravak />
  </div>*/}
    </>
  );
};
