import queries from './queries';
import authService from './authService';
import leadService from "./leadService";

export const {
  useRegisterMutation,
  useLoginMutation,
  useVerifyMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authService;
export const {
  useCreateLeadServicesMutation,
  useGetLeadServicesQuery
} = leadService;

export default queries;
export {authService};
