import React from "react";
import Logo from "../../assets/Logo/logo.png";
import reviewData from "../../data/ReviewData";
import { StarsComponent } from "./StarsComponent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export const ClientReview = () => {
  const settings = {
    centerMode: true,
    slidesToShow: 3,
    centerPadding: "0px",
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    dots: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="pb-[50px] overflow-hidden bg-svijetloPlava1">
      <div>
        <div className="flex justify-center">
          <img src={Logo} alt="our logo" className="flag-icon w-[150px] p-7" />
        </div>
        <div className="">
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-4xl text-TamnoPlava font-playfair md:text-4xl lg:text-5xl font-extrabold text-center mb-5 relative inline-block">
              Reviews
            </h1>
            <div className="h-[3px] bg-TamnoPlava rounded-full w-[30%] mb-10"></div>
          </div>
        </div>
        <div className="flex justify-center">
          <h2 className="text-[#122230] text-base lg:text-lg font-light text-center font-poppins ">
            <b className="font-bold   text-lg">Five-star Reviews</b>, pročitajte
            rezencije naših korisnika
          </h2>
        </div>
        <div className="slider-container mt-5 md:mt-10 text-center">
          <Slider {...settings}>
            {reviewData.map((data) => (
              <div key={data.id} className="px-4">
                <div className="pb-3 md:pb-8">
                  <StarsComponent stars={data.stars} />
                </div>
                <h1 className="text-primary font-bold text-[22px] pb-2">
                  {data.title}
                </h1>
                <p className="text-base lg:text-lg pb-3 text-wrap">{data.desc}</p>
                <p className="text-[#406C93] italic mb-3">{data.author}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
