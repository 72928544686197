import React from "react";
import Konsultacijeimg1 from "../../assets/images/konsultacijeimg1.jpg";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const Konsultacije = () => {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.jotformEmbedHandler(
        "iframe[id='JotFormIFrame-241632094033347']",
        "https://form.jotform.com/"
      );
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <HelmetProvider>
      <div className="flex flex-col lg:flex-col items-center justify-center  pt-[96px] px-4 md:px-8  ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Konsultacija Familia</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <div className="flex flex-col lg:flex lg:flex-row">
          <div className="lg:w-[60%] w-full mb-6 lg:mb-0">
            <img
              src={Konsultacijeimg1}
              alt="Konsultacije Familia"
              className="w-full h-auto shadow-md"
            />
          </div>
          <div className="lg:w-1/2 w-full lg:pl-14 text-center 2xl:pt-7 lg:text-left">
            <h1 className="text-4xl md:text-5xl font-bold mt-5 text-Narandzasta">
              Porodica je važan faktor u rehabilitaciji
            </h1>
            <h2 className="text-2xl font-poppins font-light mb-4 mt-5 w-[100%]">
              Uloga porodice u rehabilitaciji
            </h2>
            <p className="text-base md:text-lg leading-relaxed px-4 md:px-10 lg:px-0 text-gray-600">
              Nastojeći da naš rad učinimo što transparentnijim radimo na
              unaprjeđenju načina izvještavanja prema Vama. Svakodnevno možete
              pratiti izvještaje o zdravstvenom stanju na našem web portalu.
              Pored već postojećih sistema informisanja, radimo na unaprjeđenju
              konsultativnih sastanaka sa našim timom, prije svega u planiranju
              vremena. Konsultacije oko liječenja i rehabilitacije Vaših
              najmilijih možete zakazati sa našim timom putem forme u nastavku
              stranice. Bitno je da popunite sve podatke i navedete pitanja o
              kojima želite razgovarati kako bismo znali ko je od članova tima
              sa naše strane potreban da se uključi.
              <br /> Termin posjete je potrebno zakazati 48h ranije.
            </p>
          </div>
        </div>
        <div className="w-full mt-8">
          <iframe
            id="JotFormIFrame-241632094033347"
            title="Konsultacije"
            onLoad={() => window.parent.scrollTo(0, 0)}
            allow="geolocation; microphone; camera; fullscreen"
            src="https://form.jotform.com/241632094033347"
            frameBorder="0"
            style={{
              minWidth: "100%",
              maxWidth: "100%",
              height: "539px",
              border: "none",
            }}
            scrolling="no"
          ></iframe>
        </div>
      </div>
    </HelmetProvider>
  );
};
