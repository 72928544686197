import pkg from '../../package.json';

const env = {
  rootUrl: process.env['PUBLIC_URL'],
  environment: process.env['NODE_ENV'],
  isDevelopment: process.env['NODE_ENV'] === 'development',
  isStaging: process.env['NODE_ENV'] === 'staging',
  isTest: process.env['NODE_ENV'] === 'test',
  isProduction: process.env['NODE_ENV'] === 'production',
  app: {
    siteUrl: process.env['SITE_URL'],
    apiUrl: process.env['REACT_APP_API_URL'],
    port: process.env['PORT'],
    version: pkg.version
  },
};

export default env;
