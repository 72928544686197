// /src/components/CollapsibleSections.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import img from "../../assets/images/whyus/WhyUsFront.jpg";
import Collapsible from "react-collapsible";
import Aos from "aos";

const sections = [
  {
    key: "Povećana pokretljivost i fleksibilnost",
    title: "• Povećana pokretljivost i fleksibilnost",
    content:
      "Redovna fizikalna terapija smanjuje ukočenost zglobova i poboljšava raspon pokreta. Čak 75% starijih osoba koje sudjeluju u rehabilitacijskim programima pokazuje značajno poboljšanje pokretljivosti (Izvor: Journal of Aging and Physical Activity, 2021).",
  },
  {
    key: "Jačanje mišića i prevencija gubitka mišićne mase",
    title: "• Jačanje mišića i prevencija gubitka mišićne mase",
    content:
      "Vježbe snage održavaju mišiće, čuvaju kosti i smanjuju rizik od osteoporoze i prijeloma. Starije osobe koje redovno vježbaju imaju 40% manju vjerojatnost za gubitak mišićne mase (Izvor: The American Journal of Clinical Nutrition, 2020).",
  },
  {
    key: "Bolja ravnoteža i smanjen rizik od padova",
    title: "• Bolja ravnoteža i smanjen rizik od padova",
    content:
      "Vježbe ravnoteže smanjuju mogućnost padova i ozljeda te povećavaju samopouzdanje u kretanju. Sudionici stariji od 65 godina koji su učestvovali u programima fizikalne terapije imali su 35% manju vjerovatnost padova (Izvor: Journal of Aging and Health).",
  },
  {
    key: "Upravljanje kroničnim bolestima",
    title: "• Upravljanje hroničnim bolestima",
    content:
      "Rehabilitacija olakšava kontrolu simptoma i bolova povezanih s hroničnim bolestima, smanjuje potrebu za lijekovima i njihove nuspojave. Studija iz 2021. pokazuje da 60% starijih osoba s hroničnim bolestima koji sudjeluju u rehabilitacijskim programima doživljava smanjenje bolova i poboljšanje funkcionalnosti",
  },
  {
    key: "Unapređenje mentalnog zdravlja",
    title: "• Unapređenje mentalnog zdravlja",
    content:
      "Redovna terapija smanjuje simptome depresije i anksioznosti, poboljšava kvalitetu sna, povećava energiju i smanjuje stres. Starije osobe koje sudjeluju u programima fizikalne rehabilitacije imaju 45% manje simptoma depresije i anksioznosti (Izvor: The Gerontologist, 2019).",
  },
  {
    key: "Socijalna interakcija i podrška",
    title: "• Socijalna interakcija i podrška",
    content:
      "Rehabilitacijski programi pružaju priliku za stvaranje prijateljstava, razmjenu iskustava i prevenciju usamljenosti te kognitivnog propadanja",
  },
];

export const PrednostiRehabilitacije = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  });
  const [isOpen, setIsOpen] = useState({});

  const toggleCollapsible = (key) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <div className="flex flex-col lg:flex-row bg-svijetloPlava1 2xl:px-16">
      <div className="lg:w-[40%]  lg:h-full relative">
        <img
          src={img}
          loading="lazy"
          alt="Zasto ustanova Familia"
          className="w-full h-full object-cover filter brightness-50"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1
            data-aos="fade-up"
            data-aos-duration="3000"
            data-aos-once="true"
            className="text-center text-krem text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl font-bold"
          >
            Prednosti rehabilitacije
          </h1>
        </div>
      </div>
      <div className="lg:w-3/5 bg-TamnoPlava flex flex-col justify-between">
        <div>
          {sections.map(({ key, title, content }) => (
            <Collapsible
              key={key}
              trigger={
                <div className="w-full pt-3">
                  <hr className="border-t-1 border-krem mb-6" />
                  <button
                    onClick={() => toggleCollapsible(key)}
                    className="w-full text-left bg-TamnoPlava text-krem font-playfair font-light text-lg md:text-lg lg:text-lg xl:text-xl 2xl:text-3xl  px-4 rounded flex justify-between items-center hover:text-Narandzasta"
                  >
                    <span>{title}</span>
                    <span>{isOpen[key] ? "-" : "+"}</span>
                  </button>
                </div>
              }
              transitionTime={200}
              className="mt-4"
              open={isOpen[key]}
            >
              <div className="p-4 bg-TamnoPlava text-krem font-extralight rounded-b">
                <p>{content}</p>
              </div>
            </Collapsible>
          ))}
        </div>
        <div className="flex flex-row justify-center md:justify-start pl-7 mb-10 mt-10">
          <Link
            to="/rehabilitacija"
            className="w-48 md:w-56 h-12 md:h-14 border-2 md:border-3 border-Narandzasta rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-krem text-TamnoPlava font-semibold text-sm md:text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
          >
            Pročitaj više!
          </Link>
        </div>
      </div>
    </div>
  );
};
