import React from "react";
import Domimage from "../../assets/images/dom/dom8.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const Dom = () => {
  return (
    <div className="w-full mt-24 bg-svijetloPlava1">
      <div className="w-full lg:flex">
        <div className="w-full xl:w-7/12 2xl:w-1/2">
          <img
            src={Domimage}
            alt="Dom Familia"
            className="w-full h-[450px] md:h-[630px] lg:h-[850px] xl:h-[800px] object-cover "
          />
        </div>
        <div className="w-full p-5 md:w-4/5 md:ml-10 xl:w-5/12 text-TamnoPlava">
          <h1 className="text-3xl break-words xl:text-5xl 2xl:text-5xl align-top text-TamnoPlava mb-5 mt-10 font-extrabold">
            Dnevni boravak
          </h1>
          <h2 className="font-poppins text-2xl mb-3">
            Nastavak rehabilitacije starijih osoba: dnevni boravak za očuvanje
            rezultata i poboljšanje kvalitete života
          </h2>
          <p className="font-extralight text-base lg:text-lg  w-full xl:pl-5 2xl:pl-5">
            Nastavak rehabilitacije starijih osoba nakon akutne faze, kroz
            dnevni boravak od ključne je važnosti za očuvanje postignutih
            rezultata i poboljšanje kvalitete života. Ustanova Familia omogućava
            pacijentima da ostanu u svom kućnom ambijentu dok primaju potrebnu
            medicinsku njegu i terapiju.
          </p>
          <p className="font-extralight mt-3 text-base lg:text-lg w-full xl:pl-5 2xl:pl-5">
          Naš pristup uključuje organizirani prijevoz pacijenata iz njihovog
          doma do centra za dnevni boravak i nazad, čime se smanjuje stres i
          nelagoda dugotrajnog boravka u zdravstvenim ustanovama. Redovite
          terapije, medicinske intervencije i psihološka podrška osiguravaju
          očuvanje i unapređenje rezultata postignutih u akutnoj fazi.<br />
          Personalizirani planovi rehabilitacije prilagođeni su individualnim
          potrebama svakog pacijenta, čime se osigurava holistička skrb i
          dugoročno zdravlje.</p>
          <p className="font-extralight mt-3 text-base lg:text-lg w-full xl:pl-5 2xl:pl-5">
            Ustanova Familia pruža optimalnu njegu i rehabilitaciju dok ste i
            dalje u udobnosti doma, čime se poboljšava kvaliteta života i
            omogućuje brži oporavak.
          </p>
          <div className="mt-5 lg:mt-10 text-right p-5">
            <Link
              to="/kontakt"
              className="text-lg md:text-[22px] underline text-Narandzasta font-normal hover:text-TamnoPlava"
            >
              Kontaktirajte nas
              <FontAwesomeIcon className="ml-3" icon={faArrowRightLong} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
