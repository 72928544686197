// /src/components/CollapsibleSections.js
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Whyus1 from "../../assets/icons/whyus1.png";
import Whyus2 from "../../assets/icons/whyus2.png";
import Whyus3 from "../../assets/icons/whyus3.png";
import Whyus4 from "../../assets/icons/whyus4.png";
import Whyus5 from "../../assets/icons/whyus5.png";

import Whyus7 from "../../assets/icons/whyus7.png";
import Whyusimage10 from "../../assets/images/whyus/whyusimg10.jpg";
import Aos from "aos";

export const WhyUs = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  });
  const items = [
    {
      image: Whyus1,
      subtitle: "Individualni pristup",
      altText: "Brza procjena Icon",
      text: "Prilagođavanje medicinskog tretmana, njege i komunikacije prema potrebama i karakteristikama svakog pojedinog pacijenta.",
    },
    {
      image: Whyus2,
      subtitle: "24/7 medicinska njega ",
      altText: "24/7 medicinska njega ",
      text: "Neprekidna briga i nadzor nad pacijentima tokom dana i noći, osiguravajući da imaju pristup potrebnoj skrbi, lijekovima i podršci u svakom trenutku. Ovu vrstu njege osigurava kontinuirana prisutnost medicinskog osoblja kako bi se obezbijedile pravovremene reakcije na  medicinske potrebe ili hitne situacije.",
    },
    {
      image: Whyus3,
      subtitle: "Savremeni medicinski uređaji",
      altText: "Iskusni stručnjaci Icon",
      text: "Pristup visokotehnološkim instrumentima koji se koriste u dijagnostici, praćenju i liječenju bolesti i medicinskih stanja.",
    },
    {
      image: Whyus4,
      subtitle: "900+ korisnika",
      altText: "Moderni objekti Icon",
      text: "900+ korisnika je iskoristilo naše usluge, što jasno pokazuje povjerenje i zadovoljstvo koje zajednica ima prema našem radu.",
    },
    {
      image: Whyus5,
      subtitle: "Portal za porodice",
      altText: "Moderni objekti Icon",
      text: "Svakodnevno individualno izvještavanje o stanju pacijenta putem portala ustanove Familia, krojeno po potrebama porodica korisnika. Dostupnost svih medicinskih nalaza, fizioterapeutskih izvještaja, izvještaja okupacione terapije te socijalnih sadržaja tokom sedmice. Redovni izvještaji odgovornih  medicinskih sestara kao i foto i video zapisi terapija.",
    },
    {
      image: Whyus7,
      subtitle: "Medicinski transport",
      altText: "Medicinski transport",
      text: "Ustanova Familia nudi visoko kvalitetan, siguran i udoban prevoz do i od naše ustanove. Omogućavamo individualni transport prilagođen potrebama svakog korisnika, osiguravajući bezbijedan i prijatan dolazak i odlazak.",
    },
  ];

  return (
    <div className="flex flex-col lg:flex-row bg-svijetloPlava1 2xl:px-16">
      <div className="flex flex-col md:flex md:flex-row w-full h-auto">
        <div
          data-aos="fade-right"
          data-aos-once="true"
          className="relative w-full md:w-1/2 lg:w-1/3 h-[600px] md:h-auto text-center bg-gray-200 p-4"
        >
          <img
            loading="lazy"
            src={Whyusimage10}
            alt="Background"
            className="absolute inset-0 w-full h-full object-cover filter brightness-100"
          />
          <div className="relative  flex flex-col justify-center h-full">
            <h1
              data-aos-once="true"
              data-aos="fade-down"
              className="text-5xl md:text-5xl xl:text-6xl text-center text-TamnoPlava font-extrabold px-4"
            >
              Zašto odabrati Familiu?
            </h1>
            <div className="mt-10 flex justify-center">
              <Link
                to="/whyus"
                className="w-4/5 sm:w-2/3 md:w-1/2 h-14 border-3 border-TamnoPlava rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-white text-TamnoPlava font-semibold text-lg md:text-xl tracking-wide hover:bg-Narandzasta hover:text-white flex items-center justify-center"
              >
                Benefiti
              </Link>
            </div>
          </div>
        </div>

        <div className="flex-1 bg-white p-10 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2  gap-4">
          {items.map((item, index) => (
            <div
              data-aos-once="true"
              data-aos-duration="1000"
              data-aos="flip-left"
              key={index}
              className="flex flex-col items-center bg-gray-100 p-4 rounded"
            >
              <img
                src={item.image}
                alt={item.altText}
                className="w-28 h-28 mb-2"
              />

              <h2 className="text-lg 2xl:text-2xl font-semibold font-poppins">
                {item.subtitle}
              </h2>
              <p className="text-lg mt-5 text-center font-extralight">
                {item.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
