import React from 'react';
import PropTypes from "prop-types";

import Icon from "../Icon/Icon";
import Toast from './Toast/Toast';

const iconConfig = {
  success: {
    bgColor: 'bg-primary-2',
    icon: 'check',
    iconColor: 'fill-n-7'
  },
  progress: {
    bgColor: 'bg-accent-4',
    icon: 'dots',
    iconColor: 'fill-n-1'
  },
  deleted: {
    bgColor: 'bg-accent-1',
    icon: 'trash',
    iconColor: 'fill-n-1'
  },
  rejected: {
    bgColor: 'bg-accent-2',
    icon: 'close',
    iconColor: 'fill-n-1'
  }
};

const Notification = ({ type, message }) => {
  const { bgColor, icon, iconColor } = iconConfig[type];

  return (
    <div className="flex bg-white items-center px-3 py-1.5 rounded-lg bg-n-6 text-n-1 dark:bg-n-4">
      <div className={`flex justify-center items-center rounded-full ${bgColor}`}>
        <Icon size="md" className={iconColor} name={icon} />
      </div>
      <span className="pl-2 base1 font-semibold">{message}</span>
    </div>
  );
};

Notification.Toast = Toast;

Notification.propTypes = {
  type: 'success' | 'progress' | 'rejected' | 'deleted',
  message: PropTypes.string

}

export default Notification;
