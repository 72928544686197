import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../store/query-base';

export const TagTypes = {}

const queries = createApi({
  baseQuery,
  reducerPath: 'familiaApi',
  tagTypes: Object.values(TagTypes),
  endpoints: () => ({})
});

export default queries;
