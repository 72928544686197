import React from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useRegisterMutation} from '../../services';
import Logo from "../../assets/Logo/logo.png";

export const Register = () => {
  const [register, {isLoading, isSuccess, error}] = useRegisterMutation();
  const {handleSubmit, control, formState: {errors, isValid}} = useForm({mode: 'onChange'});

  const onSubmit = (data) => register(data);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-svijetloPlava1 to-blue-500 ">
      <div className="w-full max-w-md p-6 md:p-8 space-y-6 md:space-y-8 bg-white bg-opacity-50 shadow-lg rounded-lg mt-[110px] mb-[15px]">
        <img
          src={Logo}
          alt='Logo Familia'
          className='w-[250px] md:w-[280px] lg:w-[300px] mx-auto'
        />
        <h2 className="text-xl font-poppins md:text-3xl font-normal text-center text-gray-700">
          Registracija korisnika
        </h2>
        <form className="mt-6 md:mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="text-red-600">{error?.data?.message}</div>

          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Nevažeći email format",
              },
            }}
            render={({field: {ref, value, onChange}}) => (
              <>
                <input
                  ref={ref}
                  required
                  id="email"
                  placeholder="Email"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  value={value}
                  onChange={onChange}
                />
                {errors.email && <span className="text-red-600">{errors.email.message}</span>}
              </>
            )}
          />

          <Controller
            name="salutation"
            control={control}
            defaultValue="Mr."
            rules={{required: "Salutation is required"}}
            render={({field: {ref, value, onChange}}) => (
              <select
                ref={ref}
                required
                id="salutation"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={value}
                onChange={onChange}
              >
                <option value="Mr.">Gospodin</option>
                <option value="Mrs.">Gospođa</option>
              </select>
            )}
          />

          <Controller
            name="first_name"
            control={control}
            defaultValue=""
            rules={{
              required: "First name is required",
              pattern: {
                value: /^[^\s]+$/,
                message: "Ime ne smije imati slobodan prostor",
              },
            }}
            render={({field: {ref, value, onChange}}) => (
              <>
                <input
                  ref={ref}
                  required
                  id="first-name"
                  placeholder="Ime"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  value={value}
                  onChange={onChange}
                />
                {errors.first_name && <span className="text-red-600">{errors.first_name.message}</span>}
              </>
            )}
          />

          <Controller
            name="last_name"
            control={control}
            defaultValue=""
            rules={{
              required: "Last name is required",
              pattern: {
                value: /^[^\s]+$/,
                message: "Prezime ne smije imati slobodan prostor",
              },
            }}
            render={({field: {ref, value, onChange}}) => (
              <>
                <input
                  ref={ref}
                  required
                  id="last-name"
                  placeholder="Prezime"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  value={value}
                  onChange={onChange}
                />
                {errors.last_name && <span className="text-red-600">{errors.last_name.message}</span>}
              </>
            )}
          />

          <Controller
            name="phone_mobile"
            control={control}
            defaultValue=""
            rules={{
              required: "Phone number is required",
              pattern: {
                value: /^\+?[0-9]{8,}$/,
                message: "Telefonski broj mora imati najmanje 8 brojeva",
              },
            }}
            render={({field: {ref, value, onChange}}) => (
              <>
                <input
                  ref={ref}
                  required
                  id="phone-mobile"
                  placeholder="Telefon"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  value={value}
                  onChange={onChange}
                />
                {errors.phone_mobile && <span className="text-red-600">{errors.phone_mobile.message}</span>}
              </>
            )}
          />

          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Šifra mora biti najmanje 8 karaktera",
              },
              pattern: {
                value: /^(?=.*[A-Z])(?=.*\d)/,
                message: "Šifra mora da sadrži jedno veliko slovo i broj",
              },
            }}
            render={({field: {ref, value, onChange}}) => (
              <>
                <input
                  ref={ref}
                  required
                  id="password"
                  placeholder="Šifra"
                  type="password"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  value={value}
                  onChange={onChange}
                />
                {errors.password && <span className="text-red-600">{errors.password.message}</span>}
              </>
            )}
          />

          <button
            id="submit-button"
            disabled={!isValid || isLoading || isSuccess}
            className="w-full h-[50px] border-[3px] border-Narandzasta rounded-2xl transition-all duration-300 ease-in-out cursor-pointer bg-white text-TamnoPlava font-normal text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
            type="submit"
          >
            {isLoading ? 'Registracija u toku...' : 'Registruj se'}
          </button>
        </form>
      </div>
    </div>
  );
};
