import React from 'react'
import WorkInProgress from "../../assets/images/workInProgress.jpg"

export const SucessStories = () => {
  return (
    <div>
      <img src={WorkInProgress} alt='work in progress' className='w-full' />
    </div>
  )
}
