import React from 'react';
import PropTypes from "prop-types";
import { twMerge } from "../../utils"

import icons from './icons';

const Sizes = {
  'xxs': 12,
  'xs': 16,
  'sm': 20,
  'md': 24,
  'lg': 32,
  'xl': 36,
  '2xl': 48,
  '3xl': 60,
  '4xl': 72
}

const Icon = ({ size, name, tooltip, className, fill, onClick }) => {
  return (
    <div className="inline-block" title={tooltip}>
      <svg
        className={twMerge(`inline-block ${className}`)}
        width={Sizes[size]}
        height={Sizes[size]}
        viewBox="0 0 24 24"
        onClick={onClick}>
        <path fill={fill} d={icons[name]}></path>
      </svg>
    </div>
  );
};

Icon.propTypes = {
  size: PropTypes.oneOf(Object.keys(Sizes)),
  name: PropTypes.oneOf(Object.keys(icons)),
  tooltip: PropTypes.string,
  className: PropTypes.string,
  fill: PropTypes.string,
  onClick: PropTypes.func,
}

export default Icon;
