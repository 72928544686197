import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import VR1 from "../../assets/VR rehab/Vr rehab img.png";
import VR2 from "../../assets/VR rehab/IMG_20241016_122829.jpg";
import BackgroundImage from "../../assets/VR rehab/quest background promo.png";
import { Link } from "react-router-dom";

export const VrRehab = () => {
  return (
    <HelmetProvider>
      <section className="bg-gray-100 mt-[98px] py-16 px-4 lg:px-24">
        <Helmet>
          <meta charSet="utf-8" />
          <title>VR rehab</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>

        <div className="max-w-8xl mx-auto">
          <h2 className="text-4xl lg:text-5xl font-bold text-center lg:text-left text-gray-800 mb-2">
            VR Rehabilitacija
          </h2>
          <h2 className="text-xl lg:text-2xl text-Narandzasta font-normal font-poppins mb-5 text-center lg:text-left">
            Novi način oporavka – VR iskustvo za brži i bolji povratak u formu
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
            <div className="space-y-6">
              <p className="text-base text-gray-700">
                VR naočale pružaju značajne benefite za osobe koje su preboljele
                moždani udar, posebno u rehabilitaciji. Ova tehnologija
                omogućava pacijentima izvođenje ciljnih i kontroliranih pokreta
                u virtualnom okruženju, čime pomaže u ponovnom učenju i jačanju
                mišića te stimulira različite dijelove mozga, potičući
                neuroplastičnost i stvaranje novih neuronskih veza, što je
                ključno za oporavak.
              </p>
              <p className="text-base text-gray-700">
                Specijalizirane vježbe u VR-u doprinose poboljšanju ravnoteže i
                kontrole tijela, smanjujući rizik od padova i povreda, dok
                kognitivne vježbe pomažu u unapređenju pamćenja, pažnje i drugih
                mentalnih funkcija koje često budu pogođene moždanim udarom.
              </p>
              <iframe
                className="w-full h-80 rounded-lg shadow-lg"
                src="https://www.youtube.com/embed/Gcf_qBTAxdU"
                title="VR Rehabilitation Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="flex flex-col lg:flex-row items-center lg:space-x-6 space-y-6 lg:space-y-0">
              <img
                className="w-full lg:w-1/2 h-[600px] object-cover rounded-lg shadow-lg hover:animate-pulse hover:h-[630px] transition-all"
                src={VR1}
                alt="VR Rehabilitation"
              />
              <img
                className="w-full lg:w-1/2 h-[600px] object-cover rounded-lg shadow-lg hover:animate-pulse hover:h-[630px] transition-all"
                src={VR2}
                alt="Virtual Reality"
              />
            </div>
          </div>
        </div>

        {/* New Section */}
        <div className="relative mt-16 h-[600px] overflow-hidden">
          <img
            src={BackgroundImage}
            alt="Virtual Reality"
            className="w-full h-full object-cover"
          />
          {/* Overlay for Darkening Effect */}
          <div className="absolute inset-0 bg-black opacity-50"></div>
          {/* Text and Button Overlay */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-center text-white space-y-4 px-4">
              <h3 className="text-3xl lg:text-4xl font-bold">
                Iskusite VR Rehabilitaciju
              </h3>
              <p className="text-lg lg:text-base pb-5">
                Interaktivnost VR terapije povećava motivaciju i angažman,
                čineći rehabilitaciju zanimljivijom, a individualizirani pristup
                omogućava prilagodbu programa specifičnim potrebama i ciljevima.
              </p>
              <div className="mt-24">
                <Link
                  to="/kontakt"
                  className="bg-Narandzasta hover:bg-orange-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300"
                >
                  Kontaktirajte nas
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};
