import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLoginMutation } from '../../services';
import Logo from "../../assets/Logo/logo.png"

export const Login = () => {
  const [login, { isLoading, isSuccess, error }] = useLoginMutation();
  const { handleSubmit, control, formState: { errors, isValid } } = useForm({ mode: 'onChange' });

  const onSubmit = (data) => login(data);

  return (
    <div className="min-h-screen mt-[98px] flex items-center justify-center bg-gradient-to-r from-svijetloPlava1 to-blue-500">
      <div className="w-full max-w-md p-6 md:p-8 space-y-6 md:space-y-8 bg-white bg-opacity-50 shadow-lg rounded-lg ">
        <img 
          src={Logo} 
          alt='Logo Familia' 
          className='w-[250px] md:w-[280px] lg:w-[300px] mx-auto' 
        />
        <h2 className="text-xl font-poppins md:text-3xl font-normal text-center text-gray-700">
          Prijava
        </h2>
        <form className="mt-6 md:mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="text-red-600">{error?.data.message}</div>

          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: 'Email je obavezan.',
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Unesite validan email.'
              }
            }}
            render={({ field: { ref, value, onChange } }) => (
              <div>
                <input
                  ref={ref}
                  id="email"
                  placeholder="Email"
                  className={`w-full px-3 py-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400`}
                  value={value}
                  onChange={onChange}
                />
                {errors.email && <span className="text-red-600 text-sm">{errors.email.message}</span>}
              </div>
            )}
          />

          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: 'Password je obavezan.' }}
            render={({ field: { ref, value, onChange } }) => (
              <div>
                <input
                  ref={ref}
                  id="password"
                  placeholder="Šifra"
                  type="password"
                  className={`w-full px-3 py-2 border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400`}
                  value={value}
                  onChange={onChange}
                />
                {errors.password && <span className="text-red-600 text-sm">{errors.password.message}</span>}
              </div>
            )}
          />

          <button
            id="submit-button"
            disabled={!isValid || isLoading || isSuccess}
            className="w-full h-[50px] border-[3px] border-Narandzasta rounded-2xl transition-all duration-300 ease-in-out cursor-pointer bg-white text-TamnoPlava font-semibold text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
            type="submit"
          >
            {isLoading ? 'Prijavljujem se...' : 'Prijavi se'}
          </button>
        </form>
      </div>
    </div>
  );
};
