import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Slider from "../../pages/Pocetna/Slider";
import { MissionVision } from "./MissionVision";
import { ClientReview } from "./ClientReview";
import SameDayCare from "./SameDayCare";
import { WhyUs } from "./WhyUs";
import AnyQuestions from "./AnyQuestions";
import { PrednostiRehabilitacije } from "./PrednostiRehabilitacije";

export const Pocetna = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); 
    return () => clearTimeout(timer);
  }, []);

  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Familia pocetna</title>
          <meta name="description" content="Specijalizirani za rehabilitaciju nakon moždanog udara i lomova, orjenitrani ka integralnim i holističkim pristupom sagledavajući pacijenta i njegove potrebe u cjelosti, sa kontinuiranim praćenjem svih promjena te brzim djelovanjem." />
          <meta name="keywords" content="rehabilitacija, moždani udar, lomovi, holistički pristup, integralni pristup, demencija, dugotrajni boravak, sigurnost, socijalni sadržaji" />
          <meta name="description" content=" kvalitetna sveobuhvatnu medicinsku njegu i rehabilitaciju sa visoko kvalifikovanim medicinskim osobljem i modernim protokolima kako bismo poboljšali kvalitet života naših klijenata." />
          <link rel="canonical" href="https://www.example.com/pocetna" />
        </Helmet>
        {loading ? (
          <div className="loading">
          <svg width="64px" height="48px">
              <polyline points="0.157 23.954, 14 23.954, 21.843 48, 43 0, 50 24, 64 24" id="back"></polyline>
            <polyline points="0.157 23.954, 14 23.954, 21.843 48, 43 0, 50 24, 64 24" id="front"></polyline>
          </svg>
        </div>
        ) : (
          <>
            <Slider />
            <div>
              <MissionVision />
            </div>
            <div>
              <WhyUs />
            </div>
            <div>
              <PrednostiRehabilitacije />
            </div>
            <div>
              <SameDayCare />
            </div>
            <div>
              <AnyQuestions />
            </div>
            <div>
              <ClientReview />
            </div>
          </>
        )}
      </div>
    </HelmetProvider>
  );
};
