import React from "react";
import Posjete1 from "../../assets/images/posjeteimg1.png";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const Posjete = () => {
  return (
    <HelmetProvider>
      <div className="flex flex-col items-center justify-center pt-[96px] px-4 md:px-8 ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Posjete Familia</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-[50%] w-full mb-6 lg:mb-0">
            <img
              src={Posjete1}
              alt="Posjete Familia"
              className="w-full h-auto shadow-md"
            />
          </div>
          <div className="lg:w-1/2 w-full lg:pl-14 text-center 2xl:pt-7 lg:text-left">
            <h1 className="text-5xl md:text-5xl font-extrabold mb-4 text-Narandzasta">
              Posjete
            </h1>
            <h2 className="text-3xl md:text-3xl font-poppins font-light mb-7 mt-7 mx-auto lg:mx-0 lg:w-[60%]">
              Učinimo zajedničke trenutke nezaboravnim!
            </h2>
            <p className="w-full  mb-2 text-base md:text-lg leading-relaxed px-4 md:px-10 lg:px-0 text-gray-600 mx-auto lg:mx-0">
              Želimo da budete uključeni u proces liječenja i rehabilitacije
              Vaših najmilijih. Podrška i prisustvo najbližih članova porodice
              djeluje pozitivno na oporavak svake osobe.
            </p>
            <p className="w-full  text-base md:text-lg leading-relaxed px-4 md:px-10 lg:px-0 text-gray-600 mx-auto lg:mx-0">
              U cilju da obezbjedimo optimalne uslove za zajedničke trenutke
              naših korisnika i njihovih porodica radimo na unapređenju našh
              prostornih kapaciteta, kako vanjskih tako i unutrašnjih, ali i na
              samoj organizaciji posjeta. Organizovanje posjeta u speficičnim
              epidemiološkim uvjetima kao što je Covid naučilo nas je da
              planiranje jedan od najboljih načina prevazilaženja ovakvih
              situacija.
            </p>
            <p className="w-full  text-base md:text-lg leading-relaxed px-4 md:px-10 lg:px-0 text-gray-600 mx-auto lg:mx-0">
              Cijeneći Vaše vrijeme i našu potrebu za planiranjem dnevnih
              aktivnosti korisnika napravili smo formu putem koje trebate da
              najavite posjetu.
            </p>
            <p className="w-full  mb-2 text-base md:text-lg leading-relaxed px-4 md:px-10 lg:px-0 text-gray-600 mx-auto lg:mx-0">
              Na osnovu termina koji rezervišete mi ćemo osigurati da sve bude
              spremno za Vašu posjetu i da imate adekvatan prostor za provođenje
              vremena sa Vašim najmilijim.
            </p>
            <p className="w-full  text-base md:text-lg leading-relaxed px-4 md:px-10 lg:px-0 text-gray-600 mx-auto lg:mx-0">
              Molimo Vas da popunite formu ispod i radujemo se Vašem dolasku.
            </p>
          </div>
        </div>
        <div className="w-full items-center mt-8">
          <iframe
            id="JotFormIFrame-241641909939064"
            title="Posjeta"
            onLoad={() => window.parent.scrollTo(0, 0)}
            allow="geolocation; microphone; camera; fullscreen"
            src="https://form.jotform.com/241641909939064"
            frameBorder="0"
            style={{
              minWidth: "100%",
              maxWidth: "100%",
              height: "173rem",
              border: "none",
            }}
            scrolling="no"
          ></iframe>
          <script src="https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js"></script>
          <script>
            {`window.jotformEmbedHandler("iframe[id='JotFormIFrame-241641909939064']", "https://form.jotform.com/")`}
          </script>
        </div>
      </div>
    </HelmetProvider>
  );
};
