import React from "react";
import NavBar from "../Header/NavBar";

export const Header = () => {
  return (
    <div className="">
      <>
        <NavBar />
      </>
    </div>
  );
};
