import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

export const MissionVision = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="relative">
      <div className="background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="w-[95%] sm:w-[65%] mx-auto px-4">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-4xl pt-14 text-krem font-playfair md:text-4xl lg:text-5xl font-extrabold text-center mb-5 relative inline-block">
            Naša Misija
          </h1>
          <div className="h-[3px] bg-white rounded-full w-[75%] md:w-[40%] mb-5"></div>
        </div>
        <p
          data-aos-once="true"
          data-aos="fade-up"
          className="text-base lg:text-lg text-center font-extralight text-white leading-relaxed mb-5"
        >
          Pružiti kvalitetnu sveobuhvatnu medicinsku njegu i rehabilitaciju sa
          visoko kvalificiranim medicinskim osobljem i modernim protokolima kako
          bismo poboljšali kvalitet života naših klijenata.
        </p>
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-3xl text-krem font-playfair md:text-4xl lg:text-5xl font-extrabold text-center mb-5 relative inline-block">
            Naša Vizija
          </h1>
          <div className="h-[3px] bg-white rounded-full w-[75%] md:w-[40%] mb-5"></div>
        </div>
        <p
          data-aos-once="true"
          data-aos="fade-up"
          className="text-base lg:text-lg font-poppins font-extralight text-white text-center leading-relaxed mb-5"
        >
          Postati vodeća institucija za sveobuhvatnu medicinsku njegu i
          rehabilitaciju u našoj regiji.
        </p>
        <p
          data-aos-once="true"
          data-aos="fade-up"
          className="text-base lg:text-lg font-poppins font-extralight text-white text-center leading-relaxed "
        >
          <b>"Svaki dan je važan i vrijedan dostojanstvenog življenja!"</b>
        </p>
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-3xl text-krem font-playfair md:text-4xl lg:text-5xl font-extrabold text-center mb-5 mt-4 relative inline-block">
            O nama
          </h1>
          <div className="h-[3px] bg-white rounded-full w-[75%] md:w-[27%] mb-5"></div>
        </div>
        <p
          data-aos-once="true"
          data-aos="fade-up"
          className="text-base lg:text-lg font-poppins font-extralight text-white text-center leading-relaxed "
        >
          Specijalizirani za rehabilitaciju nakon moždanog udara i lomova, mi
          provodimo integralni i holistički pristup sagledavajući pacijenta i
          njegove potrebe u cjelosti, sa kontinuiranim praćenjem svih promjena
          te brzim djelovanjem. U našoj ustanovi za pacijente sa demencijom kao
          i za sve ostale pacijente sa potrebom za dugotrajni boravak
          osiguravamo mir, sigurnost, socijalne sadržaje, te boravak u veoma
          ugodnom vanjskom okruženju.
        </p>
        <div className="flex flex-row justify-center pb-14 items-center ">
          <Link
            data-aos-once="true"
            data-aos="fade-up"
            to="/o-nama"
            className=" text-[20px] md:text-[24px] text-Narandzasta font-normal mt-10 hover:text-white  "
          >
            Pročitaj više o nama
            <FontAwesomeIcon className="ml-3" icon={faArrowRightLong} />
          </Link>
        </div>
      </div>
    </div>
  );
};
