import React from "react";
import Whyusimage1 from "../../assets/images/whyus/whyusimg1.jpg";
import Whyusimage2 from "../../assets/images/whyus/whyusimg2.png";
import Whyusimage3 from "../../assets/images/whyus/whyusimg3.png";
import Whyusimage4 from "../../assets/images/whyus/whyusimg4.jpg";
import Whyusimage5 from "../../assets/images/whyus/whyusimg5.png";
import Whyusimage6 from "../../assets/images/whyus/whyusimg6.png";
import Whyusimage8 from "../../assets/images/whyus/whyusimg8.png";
import Whyusimage11 from "../../assets/images/whyus/whyusimg11.jpg";
import Whyusimage12 from "../../assets/images/whyus/whyusimg12.jpg";
import Whyusimage13 from "../../assets/images/whyus/whyusimg13.jpg";
import Whyusimage14 from "../../assets/images/whyus/whyusimg14.jpg";
import Whyusimage15 from "../../assets/images/whyus/whyusimg15.jpg";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const WhyUsPage = () => {
  return (
    <HelmetProvider>
      <div className="mt-[96px] bg-svijetloPlava1">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Zasto ustanova Familia</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <div className="flex flex-col items-center justify-center pt-10">
          <h1 className="text-3xl text-TamnoPlava font-playfair md:text-4xl lg:text-4xl font-extrabold text-center mb-5 relative inline-block">
            Za naše klijente, nudimo sve usluge na jednom mjestu:
          </h1>
          <div className="h-[3px] bg-TamnoPlava rounded-full w-[75%] md:w-[40%] mb-5"></div>
        </div>
        {/* 900+ korisnika */}
        <div className="p-5 md:px-16 flex justify-center">
          <div className="md:flex md:flex-row items-center max-w-6xl w-full">
            <img
              className="w-full md:w-[50%] lg:w-[35%] h-72 rounded-3xl mb-5 md:mb-0 md:mr-10"
              src={Whyusimage14}
              alt="900+ korisnika"
            />
            <div className="text-center md:text-left lg:px-5">
              <h2 className="text-3xl md:text-4xl font-extrabold text-Narandzasta mb-4">
                900+ korisnika
              </h2>
              <p className="text-base lg:text-lg font-normal">
                Preko 900 korisnika je do sada iskoristilo naše usluge, što
                jasno pokazuje visoko povjerenje i zadovoljstvo koje zajednica
                ima prema našem radu. Ovaj impresivan broj korisnika govori o
                kvaliteti naših usluga i posvećenosti pružanju najboljeg mogućeg
                iskustva svakom klijentu.
              </p>
            </div>
          </div>
        </div>
        {/* Individualni i holistički pristup */}
        <div className="p-5 md:px-16 flex justify-center">
          <div className="md:flex md:flex-row-reverse items-center max-w-6xl w-full">
            <img
              className="w-full md:w-[50%] lg:w-[35%] rounded-3xl mb-5 md:mb-0 md:mr-10"
              src={Whyusimage2}
              alt="Individualni i holistički pristup"
            />
            <div className="text-center md:text-left lg:px-5">
              <h2 className="text-3xl md:text-4xl font-extrabold text-Narandzasta mb-4">
                Individualni i holistički pristup
              </h2>
              <p className="text-base lg:text-lg font-normal">
                Prilagođavanje medicinskog tretmana, njege i komunikacije prema
                potrebama i karakteristikama za svakog pojedinog pacijenta.
              </p>
            </div>
          </div>
        </div>
        {/* Smještaj i opšta njega */}
        <div className="p-5 md:px-16 flex justify-center">
          <div className="md:flex md:flex-row items-center max-w-6xl w-full">
            <img
              className="w-full md:w-[50%] lg:w-[35%] rounded-3xl mb-5 md:mb-0 md:mr-10"
              src={Whyusimage4}
              alt="Smještaj i opšta njega"
            />
            <div className="text-center md:text-left lg:px-5">
              <h2 className="text-3xl md:text-4xl font-extrabold text-Narandzasta mb-4">
                Smještaj i opšta njega
              </h2>
              <p className="text-base lg:text-lg font-normal">
                U ustanovi Familia nudimo različite jedinice smještaja ovisno o
                potrebi pacijenta. Opšta njega je iznimno je važna jer osigurava
                održavanje lične higijene, kupanje, presvlačenje, pomoć pri
                kretanju, pomoć pri hranjenju, te svaku drugu neophodnu pomoć
                ovisno o potrebama pacijenta.
              </p>
            </div>
          </div>
        </div>

        {/*Pojačana medicinska njega*/}
        <div className="p-5 md:px-16 flex justify-center">
          <div className="md:flex md:flex-row-reverse items-center max-w-6xl w-full">
            <img
              className="w-full md:w-[50%] lg:w-[35%] rounded-3xl mb-5 md:mb-0 md:mr-10"
              src={Whyusimage1}
              alt="Pojačana medicinska njega"
            />
            <div className="text-center md:text-left lg:px-5">
              <h2 className="text-3xl md:text-4xl font-extrabold text-Narandzasta mb-4">
                Pojačana medicinska njega
              </h2>
              <p className="text-base lg:text-lg font-normal">
                Stalni nadzor doktora, mogućnost brze intervencije stručnih
                saradnika, educirano medicinsko osoblje. Brzi laboratorijski
                nalazi, ultrazučna dijagnostika unutar ustanove. Dostupnost
                medicinskih pomagala za olakšani transfer, medicinski kreveti sa
                antidekubitalnim madracima. Neprekidna briga i nadzor nad
                pacijentima tokom dana i noći, omogućava da pacijenti imaju
                pristup potrebnoj njezi, lijekovima i podršci u svakom trenutku.
              </p>
            </div>
          </div>
        </div>
        {/*Individualna fizikalna rehabilitacija */}
        <div className="p-5 md:px-16 flex justify-center">
          <div className="md:flex md:flex-row items-center max-w-6xl w-full">
            <img
              className="w-full md:w-[50%] lg:w-[35%] rounded-3xl mb-5 md:mb-0 md:mr-10"
              src={Whyusimage3}
              alt="Individualna fizikalna rehabilitacija "
            />
            <div className="text-center md:text-left lg:px-5">
              <h2 className="text-3xl md:text-4xl font-extrabold text-Narandzasta mb-4">
                Individualna fizikalna rehabilitacija
              </h2>
              <p className="text-base lg:text-lg font-normal">
                Plan i program rehabilitacije za svakog pacijenta individualno
                donosi rehabilitacijski tim – doktor specijalista fizijatar,
                glavni ordinirajući ljekar, te glavni diplomirani fizioterapeut.
                Tim terapeuta prema planu svakodnevno provodi individualne i
                zajedničke tretmane sa korisnicima usluga ustanove Familia.
              </p>
            </div>
          </div>
        </div>
        {/* Robotska rehabilitacija */}
        <div className="p-5 md:px-16 flex justify-center">
          <div className="md:flex md:flex-row-reverse items-center max-w-6xl w-full">
            <img
              className="w-full md:w-[50%] lg:w-[35%] rounded-3xl mb-5 md:mb-0 md:mr-10"
              src={Whyusimage6}
              alt="Robotska rehabilitacija"
            />
            <div className="text-center md:text-left lg:px-5">
              <h2 className="text-3xl md:text-4xl font-extrabold text-Narandzasta mb-4">
                Robotska rehabilitacija
              </h2>
              <p className="text-base lg:text-lg font-normal">
                Posebno izdvajamo najsavremenije robotske aparate koji koristimo
                unutar odjela fizikalne terapije. Robotska rehabilitacija
                omogućava objektivizaciju početnih mjerenja kao i kontrolu
                procesa rehabilitacije i objektivizaciju rezultata. Robotska
                rehabilitacija je namijenjena kako za korisnike ustanove Familia
                tako i za one koji imaju potrebu za rehabilitacijom u vidu
                individualnih sedmičnih dolazaka na zakazane termine.
              </p>
            </div>
          </div>
        </div>
        {/* Dnevni boravak */}
        <div className="p-5 md:px-16 flex justify-center">
          <div className="md:flex md:flex-row items-center max-w-6xl w-full">
            <img
              className="w-full md:w-[50%] lg:w-[35%] rounded-3xl mb-5 md:mb-0 md:mr-10"
              src={Whyusimage8}
              alt="Dnevni boravak"
            />
            <div className="text-center md:text-left lg:px-5">
              <h2 className="text-3xl md:text-4xl font-extrabold text-Narandzasta mb-4">
                Dnevni boravak
              </h2>
              <p className="text-base lg:text-lg font-normal">
                Nakon oporavka u našoj ustanovi, veoma je važno nastaviti sa
                rehabilitacijom. Nudimo mogućnost tzv. dnevnog boravka u
                određenim sedmičnim terminima.
              </p>
            </div>
          </div>
        </div>
        {/* Okupaciona terapija */}
        <div className="p-5 md:px-16 flex justify-center">
          <div className="md:flex md:flex-row-reverse items-center max-w-6xl w-full">
            <img
              className="w-full md:w-[50%] lg:w-[35%] rounded-3xl mb-5 md:mb-0 md:mr-10"
              src={Whyusimage13}
              alt="Okupaciona terapija"
            />
            <div className="text-center md:text-left lg:px-5">
              <h2 className="text-3xl md:text-4xl font-extrabold text-Narandzasta mb-4">
                Okupaciona terapija
              </h2>
              <p className="text-base lg:text-lg font-normal">
                Cilj svake rehabilitaije a posebno rehabilitacije starijih je
                uspostava narušenih samostalnih aktivnosti dnevnog života (ADŽ).
                Okupacioni fizioterapeut provodi individualnu okupacionu
                terapiju za finu i grubu motoriku, potpomognutu robotskim
                spravama radi što bržeg oporavka korisnika. Fokus rada
                okupacionog terapeuta su individualne vježbe za što brži
                povratak aktivnostima dnevnog života.
              </p>
            </div>
          </div>
        </div>
        {/* Zdrava ishrana */}
        <div className="p-5 md:px-16 flex justify-center">
          <div className="md:flex md:flex-row items-center max-w-6xl w-full">
            <img
              className="w-full md:w-[50%] lg:w-[35%] rounded-3xl mb-5 md:mb-0 md:mr-10"
              src={Whyusimage11}
              alt="Zdrava ishrana"
            />
            <div className="text-center md:text-left lg:px-5">
              <h2 className="text-3xl md:text-4xl font-extrabold text-Narandzasta mb-4">
                Zdrava ishrana
              </h2>
              <p className="text-base lg:text-lg font-normal">
                Veoma smo posvećeni pružanju sveobuhvatne brige za naše
                pacijente kroz zdravu ishranu. Posebno je razvijen meni za
                pacijente sa specifičnim potrebama/ dijetetski, proteinski,
                dijabetični .. Ordinirajući doktor daje dnevne upute za
                eventualnu promjenu menija za nekog pacijenta u slučaju potrebe.
                Obavezna je raznovrsnost menija te 5 obroka dnevno jer je
                pravilna ishrana ključna za oporavak.
              </p>
            </div>
          </div>
        </div>
        {/* Medicinski transport */}
        <div className="p-5 md:px-16 flex justify-center">
          <div className="md:flex md:flex-row-reverse items-center max-w-6xl w-full">
            <img
              className="w-full md:w-[50%] lg:w-[35%] rounded-3xl mb-5 md:mb-0 md:mr-10"
              src={Whyusimage12}
              alt="Medicinski transport"
            />
            <div className="text-center md:text-left lg:px-5">
              <h2 className="text-3xl md:text-4xl font-extrabold text-Narandzasta mb-4">
                Medicinski transport
              </h2>
              <p className="text-base lg:text-lg font-normal">
                Ustanova Familia pruža visokokvalitetne usluge prijevoza za sve
                korisnike, obuhvatajući siguran i udoban prijevoz do naše
                ustanove i nazad. Pored klasičnog transporta, naša vozila su
                opremljena za transport pacijenata sa medicinskom potporom, kao
                što je monitoring vitalnih parametara te kiseonička potpora i
                osiguravanje prohodnosti dišnih puteva. Vozila su opremljena i
                za brz i ugodan transport osoba u invalidskim kolicima.
              </p>
            </div>
          </div>
        </div>
        {/*Izleti , muzikoterapija, kognitivne vježbe */}
        <div className="p-5 md:px-16 flex justify-center">
          <div className="md:flex md:flex-row items-center max-w-6xl w-full">
            <img
              className="w-full md:w-[50%] lg:w-[35%] rounded-3xl mb-5 md:mb-0 md:mr-10"
              src={Whyusimage15}
              alt="Izleti, muzikoterapija, kognitivne vježbe"
            />
            <div className="text-center md:text-left lg:px-5">
              <h2 className="text-3xl md:text-4xl font-extrabold text-Narandzasta mb-4">
                Izleti , muzikoterapija, kognitivne vježbe
              </h2>
              <p className="text-base lg:text-lg font-normal">
                Za sve korisnike, prema njihovim željama i mogućnostima,
                organiziramo izlete ovisno o vremenskim uslovima. Kroz
                muzikoterapiju i ples unosimo promjenu u dnevne rutine a
                kognitivnim vježbama kao što su različite društvene i memorijske
                igre, kvizovi, križaljke održavamo takmičarski duh.
              </p>
            </div>
          </div>
        </div>
        {/* Lokacija */}
        <div className="p-5 md:px-16 flex justify-center">
          <div className="md:flex md:flex-row-reverse items-center max-w-6xl w-full">
            <img
              className="w-full md:w-[50%] lg:w-[35%] rounded-3xl mb-5 md:mb-0 md:mr-10"
              src={Whyusimage5}
              alt="Lokacija"
            />
            <div className="text-center md:text-left lg:px-5">
              <h2 className="text-3xl md:text-4xl font-extrabold text-Narandzasta mb-4">
                Lokacija
              </h2>
              <p className="text-base lg:text-lg font-normal">
                Prekrasan park, šetnice, unutrašnja i vanjska dvorišta, ravan
                teren, prostor za parking, izolovano od gradskog saobraćaja,
                osigurava mir i daje stalnu mogućnost boravka u prirodi.
              </p>
            </div>
          </div>
        </div>

        {/* Last section */}
      </div>
    </HelmetProvider>
  );
};
