
const TokenType= {
  token: 'token',
  refreshToken : 'refresh_token',

}

function getAuthToken(name) {
  return localStorage.getItem(name);
}

function setAuthToken(name, token) {
  localStorage.setItem(name, token);
}

function setAuthTokens(data) {
  setAuthToken(TokenType.token, data['token']);
  setAuthToken(TokenType.refreshToken, data['refreshToken']);
}

function destroyAuthTokens() {
  localStorage.removeItem(TokenType.token);
  localStorage.removeItem(TokenType.refreshToken);
}

function destroyAllTokens() {
  localStorage.removeItem(TokenType.token);
  localStorage.removeItem(TokenType.refreshToken);

  window.location.replace('/');
}

export {
  getAuthToken,
  setAuthToken,
  setAuthTokens,
  destroyAuthTokens,
  destroyAllTokens,
  TokenType
};
