import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import {Provider} from 'react-redux';
import store from './store';
import ReactGA from "react-ga4";
import router from "./utils/router";
import {Header} from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./utils/ScrollToTop";
import {notificationObserver} from './store';
import React, {useEffect} from "react";
import {Notification} from "./components";

const TRACKING_ID = "G-GNZ3179R4P";
ReactGA.initialize(TRACKING_ID);

const GAListener = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: document.title,
    });

    const handleScroll = () => {
      const scrollDepth = Math.round(
        (window.scrollY /
          (document.documentElement.scrollHeight - window.innerHeight)) *
          100
      );
      ReactGA.event({
        category: "User Engagement",
        action: "Scroll Depth",
        value: scrollDepth,
        label: `${scrollDepth}% scroll`,
        nonInteraction: true,
      });
    };

    // Track user scroll
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  return null;
};

const useButtonTracking = () => {
  useEffect(() => {
    const trackClickEvent = (e) => {
      const target = e.target;

      // Track <button> clicks
      if (target.tagName === "BUTTON") {
        ReactGA.event({
          category: "User Engagement",
          action: "Button Click",
          label: target.innerText,
        });
      }

      // Track <Link> clicks
      if (target.closest("a") && target.closest("a").tagName === "A") {
        const linkText =
          target.closest("a").innerText ||
          target.closest("a").getAttribute("href");
        ReactGA.event({
          category: "User Engagement",
          action: "Link Click",
          label: linkText,
        });
      }
    };

    document.addEventListener("click", trackClickEvent);

    return () => {
      document.removeEventListener("click", trackClickEvent);
    };
  }, []);
};

const App = () => {
  useEffect(() => notificationObserver(), []);

  // Hook to track button clicks and <Link> clicks globally
  useButtonTracking();

  return (
    <div className="min-h-[100vh] flex flex-col">
      <Provider store={store}>
        <Notification.Toast />
        <Router>
          <GAListener/>
          <Header/>
          <ScrollToTop>
            <Routes>
              {router.map((page) => (
                <Route key={page.path} path={page.path} element={page.element}/>
              ))}
            </Routes>
          </ScrollToTop>
          <div className="mt-auto">
            <Footer/>
          </div>
        </Router>
      </Provider>
    </div>
  );
};

export default App;
