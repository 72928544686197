import {
  combineReducers,
  configureStore,
  createListenerMiddleware,
} from '@reduxjs/toolkit';
import { envs } from '../config';
import queries from '../services';
import queryErrorLogger from './query-error-logger';

const rootReducer = combineReducers({
  [queries.reducerPath]: queries.reducer
});

export const listenerMiddleware = createListenerMiddleware();
export const startAppListening = listenerMiddleware.startListening;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true
    })
      .prepend(listenerMiddleware.middleware)
      .concat([queries.middleware, queryErrorLogger]),
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers(),
  devTools: !envs.isProduction
});
