import React from "react";
import Video from "../../assets/video/rehabilication_playback.mp4";
import { Link } from "react-router-dom";

const Slider = () => {
  return (
    <div className="relative h-screen mt-[96px]">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
        preload="auto"
        playsInline
      >
        <source src={Video} type="video/mp4" />
      </video>
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      <div className="absolute bottom-0 left-0 p-8 pb-20 sm:pb-14">
        <h1 className="text-krem text-4xl md:text-4xl lg:text-6xl font-thin text-center md:text-left">
          Transformišite svoju njegu
        </h1>
        <div className="flex flex-col sm:flex-row items-center md:items-baseline justify-center md:justify-start mt-5 md:mt-10">
          <p className="text-krem text-base sm:text-2xl font-thin">
            Treba Vam pregled? 
          </p>
          <Link to="/kontakt" className="ml-0 mt-5 sm:ml-5 sm:mt-0 w-34 sm:w-[w-52] h-12 sm:h-14 border-[3px] border-Narandzasta rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-transparent font-semibold text-sm sm:text-base text-krem tracking-wide hover:bg-Narandzasta hover:text-white hover:text-base hover:sm:text-lg px-6 py-3 shadow-md">
           Zakažite termin 
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Slider;
