// src/components/ResponsiveSection.js

import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const AnyQuestions = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  });
  return (
    <div className="flex flex-col p-10 md:p-20 bg-TamnoPlava space-y-4 md:space-y-0 md:space-x-4 md:flex-col md:items-center md:justify-between">
      <h1
        data-aos-once="true"
        data-aos="fade-down"
        className="text-4xl md:text-5xl text-center text-krem pb-7 font-bold"
      >
        Imate još pitanja?
      </h1>
      <p
        data-aos-once="true"
        data-aos="fade-down"
        className="pb-7 text-base lg:text-lg text-krem text-center"
      >
        Evo nekoliko brzih načina na koje Vam možemo pomoći.
      </p>
      <div className="flex flex-col  items-center space-y-4 md:space-y-0 md:flex-row md:space-x-4">
        <Link
          data-aos-once="true"
          data-aos="fade-right"
          to="/o-nama"
          className="w-60 h-12 border-[3px] border-none rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-krem text-TamnoPlava font-semibold text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
        >
          Saznaj više o uslugama
        </Link>
        <Link
          data-aos-once="true"
          data-aos="fade-left"
          to="/kontakt"
          className="w-36 h-12 border-[3px] border-none rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-krem text-TamnoPlava font-semibold text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
        >
          Zakaži poziv
        </Link>
      </div>
    </div>
  );
};

export default AnyQuestions;
