const reviewData = [
    {
        id:1,
        stars:5,
        title:'Rehabilitacija',
        desc:'Moje mame ne bi bilo da nije Doma Familia! Hvala svima',
        author:'- Dzenana.H'
    },
    {
        id:2,
        stars:5,
        title:'Fizikalna',
        desc:'Vrhunska fizikalna rehabilitacija i stručno osoblje. Sve preporuke',
        author:'- Haris.N'
    },
    {
        id:3,
        stars:5,
        title:'Osoblje',
        desc:'Prezadovoljan,osoblje je extra.Rehabilitacija vrhunska.Sve pohvale.',
        author:'- Maro.C'
    },
    {
        id:4,
        stars:5,
        title:'Najnovije vrste rehabilitacije',
        desc:'Najbolja njega i usluga',
        author:'- Alen.S'
    },
    {
        id:5,
        stars:5,
        title:'Tim',
        desc:'Sve pohvale doktorima, sestrama i kompletnom osoblju, te iskrene preporuke...Hvala',
        author:'- Fadila.S  '
    },
    {
        id:6,
        stars:5,
        title:'Intezivna',
        desc:'Usluge na visokom nivou',
        author:'- Ana.S  '
    },
]

export default reviewData;