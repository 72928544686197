import React from "react";
import Rane1 from "../../assets/images/HronicneRane/Rane1.jpg";
import Rane2 from "../../assets/images/HronicneRane/rane2.jpg";
import Rane3 from "../../assets/images/HronicneRane/rane3.jpg";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const OpstaMedicinskaNjega = () => {
  return (
    <HelmetProvider>
      <div className="">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Familia Opsta Medicinska Njega</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <div className="relative mt-[96px] lg:flex bg-svijetloPlava1">
          <div className="lg:w-1/2 xl:w-[45%] 2xl:w-[28%]">
            <div className="relative">
              <img
                className="w-full"
                src={Rane3}
                alt="Hronicne rane previjanje"
              />
              <div className="absolute inset-0 bg-black bg-opacity-20"></div>
            </div>
          </div>
          <div className="p-5 pb-20 md:pb-10   lg:pl-8 text-krem w-[95%] md:w-[80%] font-extralight lg:w-[55%] xl:w[55%] 2xl:w-[65%] pt-20 text-left md:text-left">
            <h1 className="text-4xl text-center lg:text-left sm:text-5xl text-TamnoPlava font-bold mb-7 ">
              Opšta njega
            </h1>
            <p className="text-base xl:text-lg 2xl:text-2xl text-center lg:text-left text-Narandzasta font-semibold p-0 md:px-10 ">
              Obuhvata širok spektar usluga usmjerenih na održavanje zdravlja i
              dobrobiti pacijenata, uključujući:
            </p>
            <div className="w-full md:w-[100%] p-2 sm:p-4">
              <ul className="custom-bullets list-disc list-inside pl-10 pt-5 text-lg text-TamnoPlava xl:text-lg 2xl:text-xl mt-2  font-normal ">
                <li>
                  • Pomoć pri mobilnosti: Asistencija pri hodanju, korištenju
                  hodalica, štapova ili invalidskih kolica, te pomoć pri
                  ustajanju i sjedenju.
                </li>
                <li>
                  • Pomoć pri ličnoj higijeni: Pomoć pri kupanju, tuširanju,
                  njezi kože
                </li>
                <li>
                  • Pomoć pri oblačenju: Pomoć pri odabiru i oblačenju odjeće.
                </li>
                <li>
                  • Pomoć pri hranjenju: Priprema obroka i asistencija pri
                  hranjenju.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="relative  lg:flex bg-svijetloPlava1">
          <div className="lg:w-1/2 xl:w-[45%] 2xl:w-[28%]">
            <div className="relative">
              <img
                loading="lazy"
                className="w-full"
                src={Rane1}
                alt="Hronicne rane previjanje"
              />
              <div className="absolute inset-0 bg-black bg-opacity-20"></div>
            </div>
          </div>
          <div className="p-5 pb-20 md:pb-10   lg:pl-8 text-TamnoPlava w-[95%] md:w-[80%] font-extralight lg:w-[55%] xl:w[55%] 2xl:w-[65%] pt-20 text-left md:text-left">
            <h1 className="text-4xl sm:text-5xl text-center lg:text-left text-TamnoPlava font-bold mb-7 ">
              Medicinska njega
            </h1>
            <p className="text-base xl:text-lg 2xl:text-2xl text-center lg:text-left text-Narandzasta font-semibold p-0 lg:px-10 ">
              Redovno praćenje vitalnih znakova i zdravstvenog stanja pacijenta
              kako bi se osigurala pravovremena intervencija.
            </p>
            <p className="text-base xl:text-lg 2xl:text-xl text-TamnoPlava text-center lg:text-left font-normal p-0 lg:px-10 mt-5">
              Redovno praćenje vitalnih znakova i zdravstvenog stanja pacijenta
              ključno je za osiguranje pravovremene i učinkovite medicinske
              njege. Kroz kontinuirano praćenje, medicinski tim može brzo
              prepoznati i reagirati na promjene u zdravstvenom stanju
              pacijenta, čime se poboljšava ishod liječenja i smanjuje rizik od
              komplikacija.
            </p>
          </div>
        </div>
        <div className=" bg-white">
          <h1 className="text-TamnoPlava font-extrabold text-4xl md:text-5xl pt-20 lg:pl-10 text-center lg:text-left pb-7">
            Pojačana medicinska njega:
          </h1>
          <p className="text-base xl:text-lg 2xl:text-2xl pb-7 text-Narandzasta font-medium text-center lg:text-left lg:w-[65%] lg:pl-16">
            Ove usluge su ključne za stabilizaciju i liječenje kritično bolesnih
            pacijenata te zahtijevaju visok stepen stručnosti i specijalizirane
            opreme.
          </p>
        </div>
        <div className="grid bg-white grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-4 font-extralight p-2  md:p-10">
          <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
            <h1 className="text-2xl text-TamnoPlava font-bold mb-2 ">
              1 | Kontinuirano praćenje vitalnih funkcija:
            </h1>
            <ul className="custom-bullets px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
              <li>EKG (elektrokardiogram)</li>
              <li>Praćenje krvnog tlaka</li>
              <li>Saturacija kisika</li>
              <li>Respiratorni parametri</li>
            </ul>
          </div>
          <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
            <h1 className="text-2xl text-TamnoPlava font-bold mb-2">
              2 | Respiratorna podrška:
            </h1>
            <ul className="custom-bullets px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
              <li>Oksigenacija visokom protokom</li>
              <li>
                Održavanje prohodnih respiratornih puteva kod traheostomija
              </li>
            </ul>
          </div>
          <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
            <h1 className="text-2xl text-TamnoPlava font-bold mb-2">
              3 | Hemodinamska podrška:
            </h1>
            <ul className="custom-bullets px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
              <li>
                Intravenski lijekovi za potporu krvnog tlaka (vazopresori,
                inotropi)
              </li>
              <li>Volumen zamjena tekućina (koloidi, kristaloidi)</li>
            </ul>
          </div>
          <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
            <h1 className="text-2xl text-TamnoPlava font-bold mb-2">
              4 | Podrška funkciji bubrega :
            </h1>
            <ul className="custom-bullets px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
              <li>Kontinuirana bubrežna nadomjesna terapija (CRRT)</li>
            </ul>
          </div>
          <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
            <h1 className="text-2xl text-TamnoPlava font-bold mb-2">
              5 | Nutritivna podrška:
            </h1>
            <ul className="custom-bullets px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
              <li>
                Enteralna prehrana (putem nazogastrične ili gastrostome sonde)
              </li>
              <li>Parenteralna prehrana (intravenska prehrana)</li>
            </ul>
          </div>
          <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
            <h1 className="text-2xl text-TamnoPlava font-bold mb-2">
              6 | Kontrola boli i sedacija:
            </h1>
            <ul className="custom-bullets px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
              <li>Sedativi</li>
              <li>Analgetici</li>
            </ul>
          </div>
          <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
            <h1 className="text-2xl text-TamnoPlava font-bold mb-2">
              7 | Antibiotska terapija i kontrola infekcija:
            </h1>
            <ul className="custom-bullets px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
              <li>Primjena širokog spektra antibiotika</li>
              <li>Aseptične tehnike</li>
            </ul>
          </div>
          <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
            <h1 className="text-2xl text-TamnoPlava font-bold mb-2">
              8 | Postoperativna skrb:
            </h1>
            <ul className="custom-bullets px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
              <li>Praćenje i podrška nakon velikih kirurških zahvata</li>
            </ul>
          </div>
          <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
            <h1 className="text-2xl text-TamnoPlava font-bold mb-2">
              9 | Specijalizirane intervencije:
            </h1>
            <ul className="custom-bullets px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
              <li>Kardioverzija i defibrilacija</li>
              <li>Pleasiranje perifernih venskih puteva</li>
              <li>Plasman arterijskih linija</li>
            </ul>
          </div>
          <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
            <h1 className="text-2xl text-TamnoPlava font-bold mb-2">
              10 | Multidisciplinarni pristup:
            </h1>
            <ul className="custom-bullets px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
              <li>
                Saradnja s različitim specijalistima (infektolozi, kardiolozi,
                nefrolozi, endokrinolozii, neurolozi, hirurzi)
              </li>
            </ul>
          </div>
          <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
            <h1 className="text-2xl text-TamnoPlava font-bold mb-2">
              11 | Rehabilitacija i mobilizacija:
            </h1>
            <ul className="custom-bullets px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
              <li>Rana mobilizacija i fizikalna terapija</li>
            </ul>
          </div>
        </div>
        <div className="w-[100%] h-[100%] p-2 pt-10 md:p-12 flex justify-center leading-8 bg-TamnoPlava">
          <div className="w-[95%] bg-krem flex flex-col-reverse md:flex md:flex-col-reverse lg:flex lg:flex-row justify-center">
            <div className="w-[95%] md:w-[95%] lg:w-[80%]">
              <h1 className="text-Narandzasta text-left font-extrabold text-4xl md:text-5xl pt-24 pl-5 pb-5">
                KORAK ISPRED <br />
                DRUGIH
              </h1>
              <p className="px-7 xl:px-12 2xl:px-24 pt-5 pb-5 text-base xl:text-lg 2xl:text-xl font-extralight ">
                U ustanovi Familia vjerujemo da holistički i integrirani pristup
                osigurava vrhunsku njegu i rehabilitaciju starijih osoba.
                Kombiniramo fizičke, emocionalne, socijalne i duhovne aspekte
                zdravlja kako bismo pružili cjelovitu skrb.
              </p>
              <p className="px-7 xl:px-12 2xl:px-24 pb-5 text-base xl:text-base 2xl:text-xl font-extralight">
                <b>
                  Brzina reakcije našeg tima omogućava pravovremenu
                  intervenciju, poboljšavajući ishode rehabilitacije. Naši
                  resursi uključuju najsuvremenije uređaje za dijagnostiku i
                  terapiju, personaliziran plan njege, psihološku podršku,
                  adekvatan prostor kao i terapijske vrtove u cilju opšteg
                  blagostanja naših korisnika.
                </b>
              </p>
              <p className="px-7 xl:px-12 2xl:px-24 text-base xl:text-base 2xl:text-xl font-extralight">
                Aktivno uključujemo obitelji u proces njege, pružajući im
                edukaciju i podršku. Naš integrirani tim stručnjaka sarađuje
                kako bi razumio sve aspekte života korisnika, osiguravajući
                optimalnu skrb i kvalitetu života. U Ustanovi Familia posvećeni
                smo pružanju najbolje moguće njege, sa fokusom na dostojanstvo i
                podršku za sve korisnike. Pozivamo vas da nas posjetite i osobno
                se uvjerite u kvalitetu naših usluga.
              </p>
              <div className="px-5 xl:px-12 2xl:px-24 text-center pt-10 pb-10">
                <Link
                  to="/kontakt"
                  className="w-36 h-12 border-[3px] border-Narandzasta rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-krem text-TamnoPlava font-semibold text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
                >
                  Kontakt
                </Link>
              </div>
            </div>
            <div className="">
              <img
                loading="lazy"
                className="w-[100%] h-[100%]"
                src={Rane2}
                alt="Hronicne rane"
              />
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};
