import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
  faBusinessTime,
} from "@fortawesome/free-solid-svg-icons";
import OurLocation from "../../assets/images/markerLocation.png";
import ContactUs from "../../assets/images/Contact.jpg";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const Kontakt = () => {
  return (
    <HelmetProvider>
      <div className="mx-auto px-4 mt-[96px] bg-svijetloPlava1 py-8">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Kontakt Familia</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-8 md:gap-12 p-4 md:p-8">
          <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300">
            <div className="relative rounded-lg mb-4 w-full h-[470px] overflow-hidden">
              <img
                src={ContactUs}
                alt="Contact Familia"
                className="w-full h-full object-cover object-left lg:object-center filter brightness-50 rounded-lg "
              />
              <div className="absolute inset-0 flex items-center justify-center">
                <h2 className="text-4xl lg:text-5xl text-center font-poppins font-semibold text-white">
                  Kontakt informacije
                </h2>
              </div>
            </div>
            <div className="space-y-8 pt-2">
              <div className="text-TamnoPlava font-light text-lg md:text-2xl flex items-center space-x-2">
                <FontAwesomeIcon icon={faPhone} />
                <span className="font-medium">Telefon:</span>
                <a
                  href="tel:+38733956480"
                  className="text-TamnoPlava font-light text-lg md:text-2xl"
                >
                  Ponedjeljak-Petak od 08:00-16:00 +387 33 956 480
                </a>
              </div>
              <div className="text-TamnoPlava font-light text-lg md:text-2xl flex items-center space-x-2">
                <FontAwesomeIcon icon={faEnvelope} />
                <span className="font-medium">Email:</span>
                <a
                  href="mailto:info@familia.ba"
                  className="text-TamnoPlava font-light text-lg md:text-2xl"
                >
                  info@familia.ba
                </a>
              </div>
              <div className="text-TamnoPlava font-light text-lg md:text-2xl flex items-center space-x-2">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <span className="font-medium">Address:</span>
                <a
                  href="https://www.google.com/maps/search/?api=1&query=Briješće%20Polje%204,%2071000%20Sarajevo,%20BiH"
                  className="text-TamnoPlava font-light text-lg md:text-2xl"
                >
                  Briješće Polje 4, 71000 Sarajevo, BiH
                </a>
              </div>
              <div className="text-TamnoPlava font-light text-lg md:text-2xl flex items-center space-x-2">
                <FontAwesomeIcon icon={faBusinessTime} />
                <span className="font-medium">Posjete:</span>
                <span>
                  Pon- Petak: 14:30 - 18:30, Subota: 12:00 - 18:30 Nedjelja:
                  10:00- 18:30
                </span>
              </div>
              <div className="text-TamnoPlava font-light text-lg md:text-2xl flex items-center space-x-2">
                <FontAwesomeIcon icon={faBusinessTime} />
                <span className="font-medium">Radno vrijeme:</span>
                <span>0 - 24 h</span>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300">
            <div className="relative rounded-lg mb-4 w-full h-[470px]">
              <img
                src={OurLocation}
                alt="Location Familia"
                className="w-full h-full object-cover filter brightness-50 rounded-lg"
              />
              <div className="absolute inset-0 flex items-center justify-center">
                <h2 className="text-4xl lg:text-5xl text-center font-poppins font-semibold text-white">
                  Nasa lokacija
                </h2>
              </div>
            </div>
            <div className="aspect-w-16 aspect-h-9 overflow-hidden rounded-lg">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2835.9860041378793!2d18.31490315692645!3d43.85614977890287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47505e5b5c89b27d%3A0x940ebdb0e0873b5d!2sUnknown%20Road%2C%20Unknown%20City!5e0!3m2!1sen!2sus!4v1649884691755!5m2!1sen!2sus"
                width="100%"
                height="300px"
                title="Familia location"
                allowFullScreen=""
                loading="lazy"
                className="border-0"
              ></iframe>
            </div>
          </div>
        </div>
        <div>
          <div className="">
            <iframe
              allow="gyroscope; accelerometer"
              title="JotForm"
              src="https://us22.list-manage.com/contact-form?u=6ef241caa0a7acee875a9ecba&form_id=e15c6707385e6f835450b13bc79aba21"
              width="100%"
              height="1300px"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};
