import React from "react";
import MozdaniUdar1 from "../../assets/images/Mozdani udar/mozdaniUdar1.jpg";
import MozdaniUdar2 from "../../assets/images/Mozdani udar/mozdaniUdar2.png";
import MozdaniUdar3 from "../../assets/images/Mozdani udar/mozdaniudar3.jpg";
import BeFast from "../../assets/images/Mozdani udar/befastimg.jpg";
import { Helmet } from "react-helmet";

export const MozdaniUdar = () => {
  return (
    <div className="pt-[96px]   bg-svijetloPlava1 flex flex-col items-center justify-center">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Mozdani Udar</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      <div className="w-full bg-svijetloPlava1  overflow-hidden">
        <img
          src={MozdaniUdar1}
          alt="Mozdani Udar"
          className="w-full h-[500px] object-cover"
        />
        <div className="p-5">
          <div className="lg:px-10">
            <h2 className="text-3xl break-words xl:text-5xl 2xl:text-5xl align-top text-TamnoPlava mb-10 mt-5  font-extrabold">
              Moždani udar
            </h2>
            <p className="text-gray-700 text-lg leading-relaxed">
              <b>Moždani udar (ictus cerebri) </b> nastaje kada je dotok krvi u
              određeni dio mozga prekinut ili značajno smanjen, što dovodi do
              oštećenja moždanih stanica zbog nedostatka kisika i hranjivih
              tvari. Posljedice moždanog udara mogu varirati ovisno o
              lokalizaciji i veličini oštećenja, te mogu uključivati:
            </p>
            <ul className="custom-bullets text-lg px-2 mt-5 sm:px-10 font-extralight">
              <li>
                <b>Gubitak pokretljivosti: </b>Poteškoće u izvođenju tjelesnih
                aktivnosti poput govora, hodanja i gutanja.
              </li>
              <li>
                <b>Poremećaji govora i vida: </b>Ovi simptomi mogu značajno
                utjecati na komunikaciju i svakodnevni život.
              </li>
              <li>
                <b>Emocionalni i kognitivni problemi: </b>Možda će se pojaviti
                emocionalni problemi, umor, ili gubitak pamćenja.
              </li>
            </ul>
          </div>
          <img
            src={MozdaniUdar2}
            alt="Mozdani Udar simptomi"
            className="w-full h-72 object-cover blur-[3px] mt-5 shadow-lg"
          />
          <div className="lg:px-10">
            <h2 className="text-3xl text-TamnoPlava font-semibold font-poppins mt-10">
              Vrste moždanog udara:
            </h2>
            <ul className="custom-bullets text-lg px-2 mt-5 sm:px-10 font-extralight">
              <li>
                <b>Ishemijski moždani udar:</b>Nastaje zbog začepljenja krvne
                žile u mozgu, obično zbog ugrušaka.
              </li>
              <li>
                <b>Hemoragijski moždani udar:</b>Rezultat je krvarenja unutar
                mozga ili u okolnim područjima, poznat i kao izljev krvi u
                mozak.
              </li>
              <li>
                <b>Tranzistorna ishemijska ataka (TIA),</b>poznata i kao
                mini-moždani udar, može biti znak upozorenja za ozbiljniji
                moždani udar. Iako su simptomi TIA-e slični kao kod moždanog
                udara, traju kraće od 24 sata i krvna žila u mozgu postaje
                prolazno začepljena.
              </li>
              <li>
                <b>Rehabilitacija nakon moždanog udara </b>ključna je za
                obnavljanje oštećenih funkcija. Važno je započeti rehabilitaciju
                što je prije moguće. Ciljevi rehabilitacije uključuju: smanjenje
                deficita uzrokovanog gubitkom moždanog tkiva te omogućavanje
                samostalnih aktivnosti dnevnog života u maksimalnom obimu u
                kojem je to moguće. Ustanova Familia u svom centru za
                rehabilitaciju nakon moždanog udara primjenjuje raznovrsne
                metode koje su temeljene na najnovijim istraživanjima i
                tehnologijama. Intenzivna rehabilitacija neposredno nakon
                moždanog udara može značajno poboljšati rezultate oporavka.
              </li>
            </ul>
          </div>
          <div className="w-full">
            <h1 className="text-3xl break-words xl:text-5xl 2xl:text-5xl align-top text-TamnoPlava mb-8 mt-10  font-extrabold">
              Metode u Rehabilitaciji nakon moždanog udara
            </h1>
            <img
              src={MozdaniUdar3}
              alt="Mozdani Udar simptomi"
              className="w-full h-72 object-cover p-0 mt-5 shadow-lg"
            />
            <div className="grid bg-svijetloPlava1 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4 font-extralight p-2  md:p-10">
              <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
                <h1 className="text-2xl text-TamnoPlava font-semibold mb-2 font-poppins">
                  Fizikalna terapija:
                </h1>
                <ul className="custom-bullets p-2 lg:px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
                  <li>
                    Vježbe za poboljšanje pokretljivosti: Aktivnosti usmjerene
                    na jačanje mišića i povećanje raspona pokreta.
                  </li>
                  <li>
                    Vježbe ravnoteže i koordinacije: Pomažu u vraćanju kontrole
                    nad pokretima i smanjenju rizika od padova.
                  </li>
                </ul>
              </div>
              <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
                <h1 className="text-2xl text-TamnoPlava font-semibold mb-2 font-poppins">
                  Logopedska terapija:
                </h1>
                <ul className="custom-bullets p-2 lg:px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
                  <li>
                    Vježbe govora i komunikacije: Pomažu u obnavljanju
                    sposobnosti izražavanja i razumijevanja jezika.
                  </li>
                  <li>
                    Terapija gutanja: Fokusira se na poboljšanje sposobnosti
                    gutanja kako bi se smanjila opasnost od aspiracije.
                  </li>
                </ul>
              </div>
              <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
                <h1 className="text-2xl text-TamnoPlava font-semibold mb-2 font-poppins">
                  Ergonomska terapija/Okupaciona terapija
                </h1>
                <ul className="custom-bullets p-2 lg:px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
                  <li>
                    Prilagodbe aktivnosti svakodnevnog života: Pomaže
                    pacijentima da se prilagode i obavljaju svakodnevne
                    aktivnosti s poboljšanom funkcionalnošću.
                  </li>
                </ul>
              </div>
              <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
                <h1 className="text-2xl text-TamnoPlava font-semibold mb-2 font-poppins">
                  Neurološka terapija:
                </h1>
                <ul className="custom-bullets p-2 lg:px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
                  <li>
                    Kognitivna rehabilitacija: Fokusira se na poboljšanje
                    kognitivnih funkcija kao što su pamćenje, pažnja i izvršne
                    funkcije.
                  </li>
                </ul>
              </div>
              <div className="bg-gray-100 p-4 transition duration-300 ease-in-out transform hover:shadow-xl">
                <h1 className="text-2xl text-TamnoPlava font-semibold mb-2 font-poppins">
                  Psihološka i emocionalna podrška::
                </h1>
                <ul className="custom-bullets p-2 lg:px-10 text-lg xl:text-lg 2xl:text-xl mt-2  font-extralight">
                  <li>
                    Savjetovanje i psihoterapija: Pomaže u upravljanju
                    emocionalnim izazovima i prilagodbi na promjene u životu.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex justify-center">
            <img
              src={BeFast}
              alt="Be fast pozovite pomoc"
              className="w-[95%] h-[100%]  object-cover p-0 mt-5 shadow-lg"
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
