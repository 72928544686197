import {setAuthTokens} from '../utils';
import {startAppListening} from './query-store';
import {toast} from 'react-hot-toast';
import {Notification} from "../components"

import {authService} from '../services';

const notificationObserver = () => {
  let observerList = [];

  observerList.push(
    startAppListening({
      matcher: authService.endpoints.register.matchFulfilled,
      effect: async ({payload}) => {
        toast(() => <Notification type="success" message={payload.message}/>);
        setTimeout(() => window.location.replace('/'), 5000)
      }
    })
  );

  observerList.push(
    startAppListening({
      matcher: authService.endpoints.login.matchFulfilled,
      effect: async ({payload}) => {
        setAuthTokens(payload);
        toast(() => <Notification type="success" message={payload.message}/>);
        window.location.replace('/');
      }
    })
  );

  observerList.push(
    startAppListening({
      matcher: authService.endpoints.verify.matchFulfilled,
      effect: async ({payload}) => {
        toast(() => <Notification type="success" message={payload.message}/>);
      }
    })
  );

  return () => {
    observerList.forEach((unsubscribe) => unsubscribe());
    observerList = [];
  };
};

export default notificationObserver;
