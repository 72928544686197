import React, { useEffect } from "react";
import Doctor from "../../assets/icons/doctor.png";
import Hospital from "../../assets/icons/hospital.png";
import Surgery from "../../assets/icons/surgery.png";
import Ambulance from "../../assets/icons/ambulance.png";
import { Link } from "react-router-dom";
import Aos from "aos";

const SameDayCare = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  });
  return (
    <div className="bg-siva1 pt-20 md:pt-20 ">
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-once="true"
        data-aos-duration="1000"
        className="flex flex-col items-center justify-center"
      >
        <h1 className="text-4xl text-TamnoPlava font-playfair md:text-4xl lg:text-5xl font-extrabold text-center mb-5 relative inline-block">
          Jednostavan i brz prijem
        </h1>
        <div className="Underline h-[3px] bg-TamnoPlava rounded-full w-[70%] md:w-[40%] mb-5"></div>
      </div>
      <div className="  p-2   flex flex-col items-center">
        <p className="text-center text-lg sm:text-xl  mb-6 font-light text-TamnoPlava w-full md:w-[75%]">
          Naša usluga eliminira čekanje u redu, omogućavajući pacijentima brz
          početak rehabilitacije. Stručni tim odmah procjenjuje potrebe
          pacijenata koristeći najsavremenije dijagnostičke metode te se na
          osnovu objektivnih rezutata i mjerenja pravi plan terapije i njegu
          radi ublažavanja simptoma i poboljšanja stanja.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 gap-2 w-full md:w-[100%]">
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-once="true"
            data-aos-duration="1000"
            className="flex flex-col text-center items-center  p-4 rounded"
          >
            <img
              src={Doctor}
              alt="Prijavni proces"
              className="w-20 h-20 flex-shrink-0 mr-4"
            />
            {/*Prijavni proces*/}
            <div>
              <h2 className="text-2xl md:text-3xl my-3 font-semibold text-Narandzasta">
                Prijem
              </h2>
              <p className="text-base sm:text-lg text-TamnoPlava font-extralight">
                Pacijent ili njegovi bližnji kontaktiraju ustanovu radi prijave
                za smještaj i rehabilitaciju. Ovaj korak može biti obavljen
                putem telefona, online forme ili ličnim dolaskom u ustanovu.
              </p>
            </div>
          </div>
          {/* Brza procjena */}
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-once="true"
            data-aos-duration="1000"
            className="flex flex-col text-center items-center  p-4 rounded"
          >
            <img
              src={Surgery}
              alt="Brza procjena Icon"
              className="w-20 h-20 flex-shrink-0 mr-4"
            />
            <div>
              <h2 className="text-3xl my-3 font-semibold text-Narandzasta">
                Procjena
              </h2>
              <p className="text-base sm:text-lg text-TamnoPlava font-extralight">
                Nakon prijave, ustanova najčešće organizira posjetu
                ordinirajućeg ljekara porodici kako bi se započeo proces
                procjene pacijentovih potreba. U slučaju da posjetu nije moguće
                organizirati, potrebno je dati što više informacija kako bi se
                moglo najbolje procijeniti koji tip smještaja i rehabilitacije
                je najbolji za pacijenta.
              </p>
            </div>
          </div>
          {/* Transportt*/}
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-once="true"
            data-aos-duration="1000"
            className="flex flex-col text-center items-center  p-4 rounded"
          >
            <img
              src={Ambulance}
              alt="Brza procjena Icon"
              className="w-20 h-20 flex-shrink-0 mr-4"
            />
            <div>
              <h2 className="text-3xl my-3 font-semibold text-Narandzasta">
                Transport
              </h2>
              <p className="text-base sm:text-lg text-TamnoPlava font-extralight">
                Na osnovu obostrano prikupljenih informacija, prijem pacijenta
                se može izvršiti u organizaciji ustanove ili porodice. U slučaju
                hitnosti, ustanova Familia je spremna odmah reagirati i izvršiti
                hitan medicinski transport.
              </p>
            </div>
          </div>
          {/* Prioritizacija */}
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-once="true"
            data-aos-duration="1000"
            className="flex flex-col text-center items-center  p-4 rounded"
          >
            <img
              src={Hospital}
              alt="Prioritizacija Icon"
              className="w-20 h-20 flex-shrink-0 mr-4"
            />
            <div>
              <h2 className="text-3xl my-3 font-semibold text-Narandzasta">
                Prioritizacija
              </h2>
              <p className="text-base sm:text-lg text-TamnoPlava font-extralight">
                Na osnovu prikupljenih informacija, pacijenti se klasificiraju
                prema kliničkom stanju i hitnosti te se definira odjel gdje će
                biti smješteni kao i obim i tip rehabilitacije.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-10 mb-10">
          <Link
            to="/o-nama"
            className="w-44 h-12 border-[3px] border-Narandzasta rounded-full transition-all duration-300 ease-in-out cursor-pointer bg-gray-200 text-TamnoPlava font-semibold text-base tracking-wide hover:bg-Narandzasta hover:text-white hover:text-lg flex items-center justify-center"
          >
            Zakažite termin
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SameDayCare;
