const icons = {
  dots: 'M12 10a2 2 0 1 0 0 4 2 2 0 1 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 1 0 0-4zM5 10a2 2 0 1 0 0 4 2 2 0 1 0 0-4z',
  check: 'M19.159 5.643a1.2 1.2 0 0 1 1.797 1.584l-.1.113-11 11a1.2 1.2 0 0 1-1.584.1l-.113-.1-5-5a1.2 1.2 0 0 1 1.584-1.797l.113.1 4.151 4.151L19.159 5.643z',
  trash:
    'M15 2.1a.9.9 0 0 1 .122 1.792L15 3.9H9a.9.9 0 0 1-.122-1.792L9 2.1h6zm6 3a.9.9 0 0 1 .122 1.792L21 6.9h-1.159l-.691 10.361-.045.567c-.095 1.043-.221 1.573-.523 2.102a3.9 3.9 0 0 1-1.688 1.579l-.224.101c-.332.137-.661.21-1.184.248l-.38.021-.451.013-1.147.008-3.616-.001-.51-.006-.434-.011-.37-.018-.316-.027c-.341-.038-.595-.097-.84-.189l-.21-.087-.107-.05a3.9 3.9 0 0 1-1.688-1.579l-.166-.325-.088-.223c-.054-.154-.098-.32-.135-.518l-.053-.321-.046-.38-.063-.708-.062-.878L4.158 6.9H3a.9.9 0 0 1-.122-1.792L3 5.1h18zm-2.963 1.8H5.962l.691 10.315.066.741.034.277.036.226.04.185.045.152.051.129.059.115a2.1 2.1 0 0 0 .909.85l.126.054.142.044.168.035.206.027.255.02.314.014.606.012.784.003 3.776-.003.583-.011.307-.013.252-.018.206-.024.089-.014.155-.034.132-.042.118-.051a2.1 2.1 0 0 0 .909-.85c.074-.13.131-.285.179-.511l.045-.252.04-.31.037-.378.082-1.129.637-9.559zM10 9.6a.9.9 0 0 1 .892.778l.008.122v5a.9.9 0 0 1-1.792.122L9.1 15.5v-5a.9.9 0 0 1 .9-.9zm4 0a.9.9 0 0 1 .892.778l.008.122v5a.9.9 0 0 1-1.792.122L13.1 15.5v-5a.9.9 0 0 1 .9-.9z',
  close:
    'M6.613 5.21l.094.083L12 10.585l5.293-5.292a1 1 0 0 1 1.497 1.32l-.083.094L13.414 12l5.293 5.293a1 1 0 0 1-1.32 1.497l-.094-.083L12 13.414l-5.293 5.293a1 1 0 0 1-1.497-1.32l.083-.094L10.585 12 5.293 6.707A1 1 0 0 1 6.511 5.14l.101.069z',
};

export default icons;
