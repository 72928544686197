import { useEffect, useState } from 'react';
import { getAuthToken, TokenType } from '../utils';

const useAuth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = getAuthToken(TokenType.token);

    setIsLoggedIn(!!token);
    setIsLoading(false);
  }, []);

  return [isLoading, isLoggedIn];
};

export default useAuth;
